import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  states: null,
  outlets: null,
  productCategories: null,
  showInitialOrder: true,
  showLoginModal: false,
  showRPModal: false,
  showLogoutModal: false,
  homePaths: ["/", "/about", "/restaurants", "/contact", "/blog", "/user/profile", "/user/addresses", "/user/orders", "/breakfast-menu","/privacy-policy","/terms-of-service", "/our-pizzas", "/order/details/","/payment/redirect"],
  showMobileCart: false,
  showMobileMenu: false,
  webCart: false,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setState: (state, action) => {
      state.states = action.payload;
    },
    setOutlets: (state, action) => {
      state.outlets = action.payload;
    },
    setProductCategories: (state, action) => {
      state.productCategories = action.payload;
    },
    openInitialOrder: (state) => {
      state.showInitialOrder = true;
    },
    closeInitialOrder: (state) => {
      state.showInitialOrder = false;
    },
    toggleLogin: (state) => {
      state.showLoginModal = !state.showLoginModal;
    },
    toggleResetPassword: (state) => {
      state.showRPModal = !state.showRPModal;
    },
    toggleLogout: (state) => {
      state.showLogoutModal = !state.showLogoutModal;
    },
    toggleMobileCart: (state) => {
      state.showMobileCart = true;
    },
    toggleMobileMenu: (state) => {
      state.showMobileMenu = true;
    },
    toggleWebCart: state => {
      state.webCart = !state.webCart;
    },
    closeWebCart: state => {
      state.webCart = false;
    }
  },
}); 

export const {
  setState,
  setOutlets,
  setProductCategories,
  openInitialOrder,
  closeInitialOrder,
  toggleLogin,
  toggleMobileCart,
  toggleMobileMenu,
  toggleLogout,
  toggleResetPassword,
  toggleWebCart, closeWebCart,
} = appSlice.actions;
export default appSlice.reducer;
