import React, { useState, useEffect } from "react";
import Footer from "../components/WebComponents/Footer";
import Header from "../components/WebComponents/Header";
import SideCart from "../components/WebComponents/SideCart";
import { ToastContainer } from "react-toastify";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "react-toastify/dist/ReactToastify.css";

import "../assets/css/bootstrap.min.css";
import "../assets/css/fontawesome.css";
import "../assets/css/themify-icons.css";
import "../assets/css/animate.min.css";
import "../assets/css/owl.carousel.min.css";
import "../assets/css/magnific-popup.css";
import "../assets/css/metisMenu.css";
import "../assets/css/jquery-ui.css";
import "../assets/css/nice-select.css";
import "../assets/css/datepicker.css";
import "../assets/css/jquery.timepicker.css";
import "../assets/css/common.css";
import "../assets/css/style.css";
import "../assets/css/responsive.css";
import { useDispatch, useSelector } from "react-redux";
import { openInitialOrder, toggleMobileMenu, toggleWebCart } from "../store/slices/appSlice";
import InitialOrderChoice from "../components/Modals/InitialOrderChoice";
import { useLocation } from "react-router-dom";
import useWindowDimensions from "../components/useWindowDimensions";
import ReactGA from "react-ga";
import { GOOGLE_ANALYTICS_CODE } from "../configs/app";
import { Helmet } from "react-helmet";

ReactGA.initialize(GOOGLE_ANALYTICS_CODE);

const WebsiteLayout = ({ children, isBreakfast = false }) => {
  let dispatch = useDispatch();
  let location = useLocation();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    dispatch(openInitialOrder());
  }, []);

  let { width } = useWindowDimensions();

  const { homePaths, webCart } = useSelector((state) => state.app);

  const handleSwitchCart = () => {
    dispatch(toggleWebCart());
    dispatch(toggleMobileMenu());
  };
  useEffect(() => {
    const pushAd = () => {
      try {
        // (adsbygoogle = window.adsbygoogle || []).push({});
        const adsbygoogle = window.adsbygoogle
        // console.log({ adsbygoogle })
        adsbygoogle.push({})
      } catch (e) {
        console.error(e)
      }
    }

    let interval = setInterval(() => {
      // Check if Adsense script is loaded every 300ms
      if (window.adsbygoogle) {
        pushAd()
        // clear the interval once the ad is pushed so that function isn't called indefinitely
        clearInterval(interval)
      }
    }, 300)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <>
      <ToastContainer
        position={width > 767 ? "top-left" : "bottom-center"}
        draggable
        autoClose={2000}
      />
      <Header switchCart={handleSwitchCart} isBreakfast={isBreakfast} />
      <SideCart cartStatus={webCart} switchCart={handleSwitchCart} />
      <div
        className={`body-overlay ${webCart ? "active" : ""}`}
        onClick={handleSwitchCart}
      ></div>
      <main>{children}</main>
      {/* {homePaths.some(path => location.pathname.includes(path)) && <InitialOrderChoice />} */}
      {!homePaths.includes(location.pathname) && <InitialOrderChoice />}
      <Footer />
    </>
  );
};

export default WebsiteLayout;
