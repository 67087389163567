
export const ENCRYPTION_KEY = '398h=83y497383sjdkjsbv52h38938==';
export const GooglePlacesKey = 'AIzaSyC8RYjzs9gjmUVA6cezuyFudi0bqe7ELHc';
export const NAIRA_SIGN = '₦';
export const ESTIMATED_DELIVERY_TIME = 45;
export const ESTIMATED_PICKUP_TIME = 45;
export const GOOGLE_ANALYTICS_CODE = 'UA-136156214-1';
// export const LIVE_CHAT_URL = 'https://www.tidio.com/panel/inbox/conversations?stream=unassigned';
export const LIVE_CHAT_URL = 'https://dashboard.tawk.to/#/dashboard/';

// export const API_URL = "https://genesis-restaurant-clxvf.ondigitalocean.app"
// export const API_URL = "http://188.166.168.13"
// export const SITE_URL = "http://localhost:3000"
export const SITE_URL = "https://genesisrestaurant.com.ng"
export const API_URL = "https://genesis-restaurant-clxvf.ondigitalocean.app" //"https://genesisrestaurant.com.ng"
// export const API_URL = "http://localhost:80"
export const CHOWDECK_RELAY_BASE = "https://api.chowdeck.com/relay"