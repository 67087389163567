import React, { Fragment, useState, useEffect, useRef } from "react";
import { Form, Spinner } from "react-bootstrap";
import { ChevronDown, ChevronRight, Map, MapPin } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import {
  setCustomerAddress,
  setCustomerCords,
  setSelectedOutlet,
  setSelectedOutletData,
  setSelectedState,
} from "../../store/slices/orderSlice";
import { calcCartTotal, makeTitleCase, showAlert } from "../../utils/helpers";
import useWindowDimensions from "../useWindowDimensions";
import { getDistance } from "geolib";
import Geocode from "react-geocode";
import { GooglePlacesKey } from "../../configs/app";
import { GoogleMap, Marker, withGoogleMap } from "react-google-maps";
import GooglePlacesAutocomplete , { geocodeByPlaceId, getLatLng }from 'react-google-places-autocomplete';


Geocode.setApiKey(GooglePlacesKey);
Geocode.setLanguage("en");
Geocode.setRegion("ng");
Geocode.setLocationType("ROOFTOP");

const StateAndOutletSelect = ({ type, ...props }) => {
  let dispatch = useDispatch();
  let isDelivery = type === "delivery" ? true : false;
  const { width: pageWidth } = useWindowDimensions();
  // let isMobile = pageWidth <= 767 ? true : false;
  const { states, outlets } = useSelector((state) => state.app);
  const { selectedState, selectedOutlet, customerAddress, customerCords } = useSelector((state) => state.order);
  const [stateOutlets, setStateOutlets] = useState(null);
  const [showSelects, setShowSelects] = useState(isDelivery ? false : true);
  const [address, setAddress] = useState(null);
  const [hasSelected, setHasSelected] = useState(false);
  const [loadingDistance, setLoadingDistance] = useState(false);
  const [loadingCurrentLocation, setLoadingCurrentLocation] = useState(false);
  const [addressCords, setAddressCords] = useState(null);

  const handleStateChange = (e) => {
    let selState = states.find((state) => state.slug === e.target.value);
    dispatch(setSelectedState(selState));
    let stateOutlet = outlets.filter((outlet) => outlet.state === selState._id);
    setStateOutlets(stateOutlet);
  };

  const handleSelectToggle = () => {
    setShowSelects((prev) => !prev);
  };
  const handleSelect = async (address) => {
    dispatch(setCustomerAddress(address?.label.toLowerCase()));
    setHasSelected(true);
    let result = await geocodeByPlaceId(address?.value?.place_id);
    let latLng = await getLatLng(result[0]);
    dispatch(setCustomerCords(latLng))
  };
  const getClosestOutlet = () => {
    setLoadingDistance(true);
    let val = outlets?.map((item) => {
     return {
      outlet: {
        ...item,
        id: item._id,
        name: item.name,
        slug: item.slug,
        state: item.state,
        delivering_to: item.delivering_to,
        closed: item.closed,
        location: item.location,
      },
      distance: getDistance(customerCords, item.location, 1),
    }
  });
    // console.log(val)
    let selectedOutlet = val.reduce((item, current) =>
      item["distance"] < current["distance"] ? item : current
    );
    // console.log(selectedOutlet)
    setLoadingDistance(false);
    if (Math.round(selectedOutlet.distance) > 8000) {
      return showAlert({
        title: "Oops!",
        msg: `We do not deliver to ${customerAddress} at the moment`,
        icon: "warning",
        btnText: "Try a closer address",
      });
    }
    // setShowLoadingModal(false)
    let selState = states.find(
      (state) => state._id === selectedOutlet.outlet.state
    );
    if (!selState) {
      //show not found
      return showAlert({
        title: "Oops!",
        msg: `We do not deliver to ${customerAddress} at the moment`,
        icon: "warning",
        btnText: "Try another address",
      });
    }
    dispatch(setSelectedState(selState));
    dispatch(setSelectedOutlet(selectedOutlet.outlet._id));
    dispatch(setSelectedOutletData(selectedOutlet?.outlet));
  };

  const handleCurrentLocation = (e) => {
    e.preventDefault();
    setLoadingCurrentLocation(true);
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        let initialAddress = await Geocode.fromLatLng(
          position.coords.latitude,
          position.coords.longitude
        );
        let address = initialAddress.results[0].formatted_address;
        setLoadingCurrentLocation(false);
        // setAddress(address);
        dispatch(setCustomerAddress(address));
        setHasSelected(true);
        // setAddressCords({
        //   lat: position.coords.latitude,
        //   lng: position.coords.longitude,
        // });
        dispatch(setCustomerCords({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }))
      },
      (err) => {
        setLoadingCurrentLocation(false);
        return showAlert({
          title: "Oops!",
          msg: `An error has occured, Please allow location from browser settings`,
          icon: "warning",
          btnText: "Try again",
        });
      }
    );
  };
  const handleMarkerDrag = (e) => {
    // console.log( e.nativeEvent)
    let {latitude:lat, longitude:lng} = e.nativeEvent.coordinate
    // setAddressCords(lat,  lng)
    dispatch(setCustomerCords({lat,  lng}))
   }
  const MapWithAMarker = withGoogleMap(({ coords }) => (
    <GoogleMap defaultZoom={16} defaultCenter={coords}>
      <Marker draggable  position={coords} onD onDragEnd={handleMarkerDrag} />
    </GoogleMap>
  ));
  const [value, setValue] = useState(null);

  return (
    <Form style={{ padding: 15 }}>
      {/*  */}
{/* // DELIVERY FORM */}
{isDelivery && (
  <>
    <Form.Group className="mb-3" controlId="stateSelect">
      <Form.Label>Enter a location/area close to you</Form.Label>
<div className="d-flex align-items-center">
  <GooglePlacesAutocomplete
    className="form-control"
    minLengthAutocomplete={3}
    autocompletionRequest={{
      componentRestrictions: {
        country: ['ng'],
      },
    }}
    selectProps={{
      value: customerAddress ? { label: makeTitleCase(customerAddress), value: customerAddress } : null,
      onChange: handleSelect,
      className: "w-100",
      placeholder: "Enter your address...",
    }}
  />
  <button
    disabled={!hasSelected}
    type="button"
    className="btn btn-danger btn-md ms-3 d-flex align-items-center justify-content-center"
    onClick={() => getClosestOutlet()}
  >
    {loadingDistance ? (
      <Spinner size="sm" animation="border" />
    ) : (
      <MapPin size={14} />
    )}
    <span className="ms-2">Continue</span>
  </button>
</div>
    </Form.Group>
    <div style={{marginBottom:15}}>
      <button
        disabled={loadingCurrentLocation}
        onClick={handleCurrentLocation}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: loadingCurrentLocation ? "#ccc" : "red",
        }}
      >
        <MapPin size={12} />
        <span
          style={{
            fontSize: 14,
            marginLeft: 10,
            color: loadingCurrentLocation ? "#ccc" : "red",
          }}
        >
          {loadingCurrentLocation?"Please wait...":"Use my current location"}
        </span>
      </button>
    </div>
    {customerCords && (
      <MapWithAMarker
        containerElement={<div style={{ height: `250px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
        coords={customerCords}
      />
    )}

    <p className="text-center" style={{ fontSize: 14 }}>
      Or{" "}
    </p>
  </>
)}
     {/* INSERT DELIVERY FORM HERE */}

      <p
        style={{
          color: "#000",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          border: "1px solid #ccc",
          borderLeftWidth: 0,
          borderRightWidth: 0,
          padding: "10px 0",
          cursor: "pointer",
        }}
        onClick={handleSelectToggle}
      >
        <span>
          {isDelivery
            ? "Select a restaurant close to you"
            : "Select outlet to pickup your order"}
        </span>
        {showSelects ? <ChevronDown size={20} /> : <ChevronRight size={20} />}
      </p>

      {showSelects && (
        <>
          <Form.Group className="mb-3" controlId="stateSelect">
            <Form.Label>Where are you ordering from?</Form.Label>
            <Form.Select
              aria-label="Select State"
              size="md"
              value={selectedState?.slug}
              onChange={handleStateChange}
            >
              <option value={null}>Select State</option>
              {states?.map((state, index) => (
                <option
                  key={`gedh-${Math.floor(Math.random() * 9999)}-${index}`}
                  value={state.slug}
                >
                  {makeTitleCase(state.name)}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3" controlId="outletSelect">
            <Form.Label>Choose a restaurant outlet</Form.Label>
            <Form.Select
              aria-label="Select Outlet"
              size="md"
              value={selectedOutlet?._id}
              onChange={(e) =>{
                dispatch(setSelectedOutlet(e.target.value))
                let selectedOutlet = stateOutlets.find((outlet) => outlet._id === e.target.value);
                dispatch(setSelectedOutletData(selectedOutlet));
              }}
              disabled={!selectedState ? true : false}
            >
              {!stateOutlets ||
                (!selectedState && (
                  <option value={null}>Select restaurant</option>
                ))}
              <option value={null}>Select restaurant</option>
              {stateOutlets &&
                selectedState &&
                stateOutlets.map((data, index) => (
                  <option
                    key={`hew-${Math.floor(Math.random() * 9999)}-${index}`}
                    value={data._id}
                  >
                    {data.name}
                  </option>
                ))}
            </Form.Select>
          </Form.Group>
        </>
      )}
    </Form>
  );
};

export default StateAndOutletSelect;



