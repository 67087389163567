import { useMutation, useQuery } from "@tanstack/react-query";
import React from "react";
import { useState, useEffect } from "react";
import { Alert, Button, Card, Col, Form, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { checkCouponCode } from "../api/admin.api";
import { register } from "../api/auth.api";
import { placeOrder } from "../api/order.api";
import { initializePayment, verifyBaniPayment } from "../api/payment.api";
import ForgotPasswordModal from "../components/Modals/ForgotPasswordModal";
import LoadingModal from "../components/Modals/LoadingModal";
import LoginModal from "../components/Modals/LoginModal";
import WebsiteLayout from "../layouts/WebsiteLayout";
import { toggleLogin, toggleLogout } from "../store/slices/appSlice";
import { setCustomerAddress, setCustomerCords, setDeliveryType, setStoreCoupon } from "../store/slices/orderSlice";
import { setUserInfo, setUserRef } from "../store/slices/userSlice";
import { cookieRemove, cookieStore } from "../utils/cookie";
import { trackCheckout } from "../utils/facebookPixelEvents";
import { displayAmount, makeTitleCase, showAlert } from "../utils/helpers";
import useCheckout from "bani-react";
import { API_URL } from "../configs/app";
import GooglePlacesAutocomplete, { geocodeByPlaceId, getLatLng } from "react-google-places-autocomplete";
import { getDeliveryFee } from "../api/chowdeck_relay.api";

const squadLogo = require("../assets/images/squad_logo.png");
const paystackLogo = require("../assets/images/paystack_logo.png");
const baniLogo = require("../assets/images/bani_logo.png");
// import { ReactComponent as MyLogo } from './logo.svg';

const Checkout = () => {
  let dispatch = useDispatch();
  const [hasValidCoupon, setHasValidCoupon] = useState(false)
  const [couponText, setCouponText] = useState("");
  const [createAccount, setCreateAccount] = useState(false);
  const [prematureClose, setPrematureClose] = useState(false);
  const { showLoginModal, outlets, showRPModal } = useSelector(
    (state) => state.app
  );
  const { userInfo } = useSelector((state) => state.user);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const { BaniPopUp } = useCheckout();

  let promoDeliveryFee = 1500;
  let {
    cart,
    deliveryType,
    selectedOutlet: selectedOutletSlug,
    selectedState,
    coupon,
    customerAddress,
    customerCords,
    selectedOutletData,
  } = useSelector((state) => state.order);

  let selectedOutlet = outlets?.find(
    (item) => item._id === selectedOutletSlug
  );

  const cartTotal = cart.reduce((n, { total }) => n + total, 0);
  const hasPromo = cart.find((item) => item?.product?.is_promo === true);

  let userAddress = userInfo
    ? userInfo?.address?.find((addr) => addr.primary === true)
    : "";

  const [formData, setFormData] = useState({
    name: userInfo ? userInfo?.name : "",
    email: userInfo ? userInfo?.email : "",
    phone: userInfo ? userInfo?.phone : "",
    outletSlug: selectedOutlet.slug,
    outlet: selectedOutlet,
    address: customerAddress,// userAddress,
    note: "",
    // deliveryFee: selectedState.slug === "rivers"?promoDeliveryFee:selectedOutlet?.delivering_to[0].amount,
    password: "",
    dob: "",
    paymentChannel: "paystack",
    areaName: selectedOutlet?.delivering_to[0].name,
    area: selectedOutlet?.delivering_to[0],
    deliveryFee:  selectedState.slug === "rivers"?promoDeliveryFee:selectedOutlet?.delivering_to[0].amount,
    deliveryChannel: "regular",
    canDeliverWithChowdeck:false,
    chowdeckDeliveryFee:0,
  });

  let payload = {
    channel: "WEBSITE",
    cart,
    createAccount,
    state: selectedState,
    deliveryType,
    user: formData,
    coupon,
    userid: userInfo ? userInfo._id : null,
  };

  const {
    mutate: doPlaceOrder,
    isLoading,
    isSuccess: isPlaceOrderSuccess,
    data: orderData,
  } = useMutation(placeOrder, {
    onSuccess: (data) => {
      const {name, email, phone, paymentChannel, deliveryFee} = formData;
      if (paymentChannel === 'bani') {
        BaniPopUp({
          amount: calculateAmount(deliveryType, cartTotal, deliveryFee, coupon),
          phoneNumber: phone,
          email: email,
          firstName: name.split(" ")[0],
          lastName: name.split(" ")[1] || "",
          merchantKey: "pub_test_C1Z01YBY6EWSWP0X59KMP",
          merchantRef: data.value.oid,
          metadata: data.value.oid,
          callback: function (response) {
            window.location = `/bani/redirect?reference=${data.value.oid}`
          }, 
          onClose: function () {
            setPrematureClose(true)
          }
        });
      }
    },
    onError: (err) =>
      showAlert({
        title: "Error!",
        msg: "An internal error has occurred",
        icon: "error",
        btnText: "Close",
      }),
  });

  const {
    mutate: paymentResponse,
    isLoading: loadingPayment,
    isSuccess: successPayment,
    isError: errorPayment,
  } = useMutation(initializePayment, {
    onSuccess: (data) => {
      // console.log("paymentResponse")
      const { authorization_url, reference, checkout_url } = data?.value;
      // console.log(authorization_url);
      if (authorization_url) {
        window.location = authorization_url;
      }
      if (checkout_url) {
        window.location = checkout_url;
      }
    },
  });

  const {
    mutate: doRegister,
    isLoading: isLoadingRegister,
    isSuccess: isSuccessRegister,
  } = useMutation(register, {
    onSuccess: async (data) => {
      if (!data.status)
        return showAlert({
          title: "Oops!",
          msg:
            typeof data.errors[0].msg == "string"
              ? data.errors[0].msg
              : data.errors[0].msg.message,
          icon: "error",
          btnText: "Login Instead",
          callback: () => {
            dispatch(toggleLogin());
          },
        });
      // console.log("doRegister",data);
      await cookieStore("authToken", data?.value.token);
      await doPlaceOrder({ ...payload, userid: data?.value?.newUser._id });
    },
    onError: (err) =>
      showAlert({
        title: "Error!",
        msg: "An internal error has occurred",
        icon: "error",
        btnText: "Close",
      }),
  });

  function calculateAmount(deliveryType, cartTotal, deliveryFee, coupon) {
    const isDelivery = deliveryType === "delivery";
    const baseAmount = isDelivery ? cartTotal + deliveryFee : cartTotal;
    const finalAmount = coupon
      ? baseAmount - coupon.discountedAmount
      : baseAmount;
    return finalAmount;
  }

  const handleShowLogin = (e) => {
    e.preventDefault();
    dispatch(toggleLogin());
  };
  const formatSummaryField = (title, value, isNegative = false, deliveryPromo = false) => (
    <div className="dflex align-center justify-between mt-15">
      <span>{title}</span>
      <span>
        {isNegative ? "- " : null}
        {/* {deliveryPromo && <><del style={{fontSize:14, color:"#c3c3c3"}}>₦1500.00</del><br/></>} */}
        {value}
      </span>
    </div>
  );

  // const handleOutletChange = (e) => {
  //   let selOutletSlug = e.target.value;
  //   let selectedOutlet = outlets?.find((item) => item.slug === selOutletSlug);
  //   setFormData((prev) => ({
  //     ...prev,
  //     outletSlug: selOutletSlug,
  //     outlet: selectedOutlet,
  //     areaName: selectedOutlet?.delivering_to[0].name,
  //     area: selectedOutlet?.delivering_to[0],
  //     deliveryFee: selectedOutlet?.delivering_to[0].amount,
  //   }));
  // };

  const changeDeliveryType = (e) => {
    e.preventDefault();
    dispatch(setDeliveryType(e.target.value));
  };

  // const handleAreaChange = (e) => {
  //   let areaName = e.target.value;
  //   // let selectedArea = formData?.outlet?.delivering_to.find(
  //   //   (item) => item.name === areaName
  //   // );
  //   let selectedArea = formData?.outlet?.delivering_to[0];
  //   setFormData((prev) => ({
  //     ...prev,
  //     area: selectedArea,
  //     areaName: selectedArea.name,
  //     deliveryFee: selectedState.slug === "rivers"?promoDeliveryFee:selectedArea.amount,
  //   }));
  // };

  const handleInputChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    //validation
    let {
      name,
      email,
      phone,
      areaName,
      password,
      dob,
      paymentChannel,
      deliveryFee,
    } = formData;
    if (!name.trim() || !email.trim() || !phone.trim())
      return showAlert({
        title: "Missing Field(s)!",
        msg: "You have missed some customer information field(s)",
        icon: "warning",
        btnText: "Close",
      });

    if (cart?.length < 1)
      return showAlert({
        title: "Error!",
        msg: "You don't have any item in your cart",
        icon: "warning",
        btnText: "Close",
      });

    if (deliveryType === "delivery" && !areaName.trim())
      return showAlert({
        title: "Missing Field!",
        msg: "You have to select an area for delivery",
        icon: "warning",
        btnText: "Close",
      });

    if (createAccount) {
      if (!password)
        return showAlert({
          title: "Missing Field!",
          msg: "Enter a password to create an account",
          icon: "warning",
          btnText: "Close",
        });
      // if (!dob)
      // return showAlert({
      //   title: "Missing Field!",
      //   msg: "Kindly enter a date of birth to create an account",
      //   icon: "warning",
      //   btnText: "Close",
      // });
      await doRegister(formData);
    } else {
      await doPlaceOrder(payload);
    }
  };

  const handleLogout = async () => {
    setIsLoggingOut(true);
    await dispatch(setUserInfo(null));
    await cookieRemove("authToken");
    setFormData((prev) => ({
      ...prev,
      name: "",
      email: "",
      phone: "",
      deliveryChannel: "regular",
      canDeliverWithChowdeck:false,
      chowdeckDeliveryFee:0,
    }));
    setTimeout(() => setIsLoggingOut(false), 1000);
  };

  // const handleShowLogout = (e) => {
  //   e.preventDefault();
  //   dispatch(toggleLogout());
  // };

  const outletsInState = outlets?.filter(
    (outlet) => outlet.state === selectedState?._id
  );

  useEffect(() => {
    let userAddress = userInfo?.address?.find((addr) => addr.primary === true);
    if (userInfo) {
      setFormData((prev) => ({
        ...prev,
        name: userInfo?.name,
        email: userInfo?.email,
        phone: userInfo?.phone,
        address: userAddress?.name,
      }));
      setCreateAccount(false);
    }
  }, [userInfo]);

  useEffect(() => {
    if (isPlaceOrderSuccess) {
      if (orderData?.status) {
        // console.log("doPlaceOrder",orderData)
        const { sumTotal, customer, oid, paymentChannel } = orderData?.value;
        //set user ref
        dispatch(setUserRef(oid));
        // initialize payment
        // console.log("initializing payment", paymentChannel);
        paymentResponse({
          email: customer.email,
          name: customer.name,
          phone: customer.phone,
          amount: sumTotal,
          reference: oid,
          paymentChannel,
          coupon: hasValidCoupon
        });
      } else {
        showAlert({
          title: "Oops!",
          msg: "An error has occured",
          icon: "error",
          btnText: "Close",
        });
      }
    }
  }, [isPlaceOrderSuccess]);

  useEffect(() => {
    trackCheckout();
  }, []);

 
  const { mutate: doCouponCheck, isLoading: loadingCoupon } = useMutation(
    checkCouponCode,
    {
      onSuccess: (data) => {
        // console.log(data);
        if (!data.status && data?.errors)
          return showAlert({
            title: "Oops!",
            msg: data?.errors[0].msg,
            icon: "error",
            btnText: "close",
          });
        showAlert({
          title: "Congratulations!",
          msg: "Coupon was applied successfully",
          icon: "success",
          btnText: "Continue",
        });
        setHasValidCoupon(data?.value?.couponCode)
        dispatch(setStoreCoupon(data?.value));
      },
      onError: (err) =>
        showAlert({
          title: "Error!",
          msg: "An internal error has occured",
          icon: "error",
          btnText: "close",
        }),
    }
  );

  const handleCouponCheck = (e) => {
    e.preventDefault();
    doCouponCheck({ coupon: couponText, totalAmount: cartTotal });
  };

  const handleSelect = async (address) => {
    dispatch(setCustomerAddress(address?.label.toLowerCase()));
    let result = await geocodeByPlaceId(address?.value?.place_id);
    let latLng = await getLatLng(result[0]);
    dispatch(setCustomerCords(latLng))
  };

  const {data:deliveryFeeData, isLoading:gettingDeliveryFee} = useQuery({
    enabled: deliveryType === "delivery"?true:false,
    queryKey:["getDeliveryFee",selectedOutletData?.location, customerCords],
    queryFn: getDeliveryFee,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      // console.log(data)
      if(data.status === "success"){
        setFormData((prev) => ({
          ...prev,
          deliveryFee: data?.data?.total_amount/100,
          deliveryChannel: "chowdeck",
          canDeliverWithChowdeck: true,
          chowdeckDeliveryFee: data?.data?.total_amount/100,
          feeId: data?.data?.id
        }));
      }
    }
  })
  // console.log(deliveryFeeData)
  
  useEffect(() => {
    if(formData.deliveryChannel === "regular"){
      setFormData((prev) => ({
        ...prev,
        deliveryFee: selectedOutlet?.delivering_to[0].amount,
      }));
    }else if(formData.deliveryChannel === "chowdeck"){
      setFormData((prev) => ({
        ...prev,
        deliveryFee: formData.chowdeckDeliveryFee,
      }));
    }
  }, [formData.deliveryChannel])
  
  return (
    <WebsiteLayout>
      {/* {showLoginModal && <LoginModal />} */}
      {showRPModal && <ForgotPasswordModal />}
      <section className="checkout_area pt-120 pb-120">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="checkout_wrap">
                <div className="row">
                  <div className="col-xl-8 col-lg-8">
                    {userInfo && (
                      <div className="row">
                        <div className="col-xl-12 col-lg-12 mb-30">
                          Not <strong>{makeTitleCase(userInfo?.name)}</strong>?
                          <button
                            onClick={handleLogout}
                            className="btn btn-outline btn-md text-danger"
                          >
                            Logout
                          </button>
                        </div>
                      </div>
                    )}

                    {!userInfo && (
                      <div className="row">
                        <div className="col-xl-12 col-lg-12 mb-30">
                          Already have an account?
                          <button
                            onClick={handleShowLogin}
                            className="btn btn-outline btn-md text-danger"
                          >
                            Login
                          </button>
                          <span>or fill the form to create a new account </span>
                        </div>
                      </div>
                    )}

                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col>
                          <Form.Group className="mb-3" controlId="formName">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              value={formData.name}
                              onChange={(e) =>
                                handleInputChange("name", e.target.value)
                              }
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <Form.Group className="mb-3" controlId="formPhone">
                            <Form.Label>Phone</Form.Label>
                            <Form.Control
                              type="tel"
                              placeholder=""
                              value={formData.phone}
                              onChange={(e) =>
                                handleInputChange("phone", e.target.value)
                              }
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className="mb-3" controlId="formEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder=""
                              value={formData.email}
                              onChange={(e) =>
                                handleInputChange("email", e.target.value)
                              }
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <Form.Group className="mb-3" controlId="formState">
                            <Form.Label>
                              How do you want to receive your order?
                            </Form.Label>
                            <Form.Select
                              aria-label="formDeliveryType"
                              onChange={changeDeliveryType}
                              value={deliveryType}
                            >
                              <option value="delivery">
                                Home/Office Delivery
                              </option>
                              <option value="pickup">
                                Pickup at the outlet
                              </option>
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        
                        {/* {deliveryType === "delivery" && (
                          <Col>
                            <Form.Group className="mb-3" controlId="formArea">
                              <Form.Label>Delivery Area</Form.Label>
                              <Form.Select
                                aria-label="formArea"
                                onChange={handleAreaChange}
                                value={formData.areaName}
                              >
                                <option disabled selected value="">
                                  Select Area
                                </option>
                                {formData?.outlet?.delivering_to.map(
                                  (area, index) => (
                                    <option
                                      key={`fcw-${Math.floor(
                                        Math.random() * 9999
                                      )}-${index}`}
                                      value={area.name}
                                    >
                                      {makeTitleCase(area.name)}
                                    </option>
                                  )
                                )}
                              </Form.Select>
                            </Form.Group>
                          </Col>
                        )} */}
                      </Row>

                      {deliveryType === "delivery" && (
                        <Row>
                          <Col>
                            <Form.Group
                              className="mb-3"
                              controlId="formAddress"
                            >
                              <Form.Label>Address</Form.Label>
                              {/* <Form.Control
                                type="text"
                                placeholder=""
                                value={customerAddress}
                                // value={formData.address}
                                onChange={(e) =>
                                  handleInputChange("address", e.target.value)
                                }
                              /> */}
                              <GooglePlacesAutocomplete
    className="form-control"
    minLengthAutocomplete={3}
    autocompletionRequest={{
      componentRestrictions: {
        country: ['ng'],
      },
    }}
    selectProps={{
      value: customerAddress ? { label: makeTitleCase(customerAddress), value: customerAddress } : null,
      onChange: handleSelect,
      className: "w-100",
      placeholder: "Enter your address...",
    }}
  />
                            </Form.Group>
                          </Col>
                        </Row>
                      )}

                      <Row>
                        <Col>
                          <Form.Group className="mb-3" controlId="formInfo">
                            <Form.Label>Additional Information</Form.Label>
                            <Form.Control
                              as="textarea"
                              // rows={5}
                              cols={5}
                              placeholder="Note about your order, Special note for delivery or pickup or information about any allergy"
                              value={formData.note}
                              onChange={(e) =>
                                handleInputChange("note", e.target.value)
                              }
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      {!userInfo && (
                        <Row>
                          <Col>
                            <Form.Check
                              className="checkbox mb-3"
                              checked={createAccount}
                              onClick={() => setCreateAccount(!createAccount)}
                              type="checkbox"
                              id="account-checkbox"
                              label="Create A New Account (so you don't have to fill this form next time)"
                            />
                          </Col>
                        </Row>
                      )}

                      {createAccount && !userInfo && (
                        <Row>
                          <Col>
                            <Form.Group
                              className="mb-3"
                              controlId="formPassword"
                            >
                              <Form.Label>Enter a new password</Form.Label>
                              <Form.Control
                                type="password"
                                placeholder=""
                                value={formData.password}
                                onChange={(e) =>
                                  handleInputChange("password", e.target.value)
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group
                              className="mb-3"
                              controlId="formBirthday"
                            >
                              <Form.Label>Date of Birth</Form.Label>
                              <Form.Control
                                type="date"
                                value={formData.dob}
                                onChange={(e) =>
                                  handleInputChange("dob", e.target.value)
                                }
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      )}

{deliveryType === "delivery" &&   <Card className="mt-3 mb-3">
                                    <Card.Body>
                                      <Card.Title>Delivery option</Card.Title>
                                      <div className="">
                                        { formData.canDeliverWithChowdeck && <Form.Check
                                        inline={true}
                                        className="mr-5"
                                          type="radio"
                                          name="delivery-speed"
                                          id="swift-delivery"
                                          label={
                                            <div className="d-flex align-items-top gap-3">
                                              <i className={`fas fa-bolt fs-6 ${formData.deliveryChannel === "chowdeck"?`text-primary`:""}`}></i>
                                              <div>
                                                <div className={`fw-bold fs-6 ${formData.deliveryChannel === "chowdeck"?`text-primary`:""}`}>Swift Delivery</div>
                                                <div className="text-muted">Delivers within 30 mins with order tracking</div>
                                              </div>
                                            </div>
                                          }
                                          checked={formData.deliveryChannel === "chowdeck"}
                                          onClick={() =>
                                            setFormData((prev) => ({
                                              ...prev,
                                              deliveryChannel: "chowdeck",
                                            }))
                                          }
                                        />}

                                        <Form.Check
                                        className=""
                                        inline={true}
                                          type="radio"
                                          name="delivery-speed"
                                          id="regular-delivery"
                                          label={
                                            <div className="d-flex align-items-top gap-3">
                                              <i className={`fas fa-clock fs-6 ${formData.deliveryChannel === "regular"?`text-primary`:""}`}></i>
                                              <div>
                                                <div className={`fw-bold fs-6 ${formData.deliveryChannel === "regular"?`text-primary`:""}`}>Regular Delivery</div>
                                                <div className="text-muted">Can take up to 90 mins</div>
                                              </div>
                                            </div>
                                          }
                                          checked={formData.deliveryChannel === "regular"}
                                          onClick={() =>
                                            setFormData((prev) => ({
                                              ...prev,
                                              deliveryChannel: "regular",
                                            }))
                                          }
                                        />
                                      </div>
                                    </Card.Body>
                                  </Card>
}

                      <Card style={{}}>
                        <Card.Body>
                          <Card.Title>Payment option</Card.Title>
                          <div
                            key={`payment-options`}
                            className="flex flex-row"
                          >
                            <Form.Check
                              inline={true}
                              type={"radio"}
                              name="payment-radio"
                              id={`paystack`}
                              className="mb-3 mt-3 mr-5"
                              label={
                                <img
                                  src={paystackLogo}
                                  style={{
                                    width: 120,
                                    filter:
                                      formData.paymentChannel !== "paystack" &&
                                      "grayscale(100%)",
                                  }}
                                />
                              }
                              checked={formData.paymentChannel === "paystack"}
                              onClick={() =>
                                setFormData((prev) => ({
                                  ...prev,
                                  paymentChannel: "paystack",
                                }))
                              }
                            />
                            {/* <Form.Check
                              inline={true}
                              type={"radio"}
                              name="payment-radio"
                              id={`bani`}
                              className="mb-3 mt-3 mr-5"
                              label={
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    src={baniLogo}
                                    style={{
                                      width: 25,
                                      height: 25,
                                      filter:
                                        formData.paymentChannel !== "bani" &&
                                        "grayscale(100%)",
                                    }}
                                  />
                                  <h3 className="text-[12px] m-0">bani</h3>
                                </div>
                              }
                              checked={formData.paymentChannel === "bani"}
                              onClick={() =>
                                setFormData((prev) => ({
                                  ...prev,
                                  paymentChannel: "bani",
                                }))
                              }
                            /> */}
                            <Form.Check
                              inline={true}
                              type={"radio"}
                              name="payment-radio"
                              id={`squad`}
                              className="mb-3 mt-3"
                              label={
                                <img
                                  src={squadLogo}
                                  style={{
                                    width: 100,
                                    filter:
                                      formData.paymentChannel !== "squad" &&
                                      "grayscale(100%)",
                                  }}
                                />
                              }
                              checked={formData.paymentChannel === "squad"}
                              onClick={() =>
                                setFormData((prev) => ({
                                  ...prev,
                                  paymentChannel: "squad",
                                }))
                              }
                            />
                          </div>
                        </Card.Body>
                      </Card>

                      {deliveryType === "delivery" &&
                        (selectedState?.name === "delta" ||
                          selectedState?.name === "abuja" ||
                          selectedState?.name === "imo" ||
                          selectedState?.name === "enugu") && (
                          <Alert key={"deliveryWarning"} variant={"warning"}>
                            <Alert.Heading>Delivery Fee</Alert.Heading>
                            <p>
                              You will be charged separately for delivery as the
                              delivery fee in your location fluctuates{" "}
                            </p>
                          </Alert>
                        )}

                      <Row>
                        <Col>
                          <Button
                            variant="primary"
                            type="submit"
                            className="mt-30 thm_btn thm_btn-black thm_btn-2 mb-5"
                            disabled={
                              isLoadingRegister ||
                              isLoading ||
                              (isPlaceOrderSuccess && formData.paymentChannel !== 'bani' && !prematureClose)
                            }
                          >
                            {(formData.paymentChannel !== "bani" && isPlaceOrderSuccess && !prematureClose)
                              ? "Just a sec..."
                              : loadingPayment
                              ? "Loading Payment..."
                              : isLoadingRegister
                              ? "Creating Account..."
                              : isLoading
                              ? "Saving..."
                              : "Make Payment "}
                            {!loadingPayment &&
                              !isLoadingRegister &&
                              !isLoading &&
                              displayAmount(
                                deliveryType === "delivery"
                                  ? coupon
                                    ? cartTotal -
                                      coupon?.discountedAmount +
                                      formData.deliveryFee
                                    : cartTotal + formData.deliveryFee
                                  : coupon
                                  ? cartTotal - coupon?.discountedAmount
                                  : cartTotal,
                                2
                              )}
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                    {/* </form> */}
                  </div>

                  <div className="col-xl-4 col-lg-4 col-12">
                    <div className="cart_tutal_wrap">
                      <h3 className="title">Order Details</h3>
                      <div
                        style={{
                          backgroundColor: "#cd0000",
                          color: "#fff",
                          padding: "30px 20px",
                        }}
                      >
                        <div className="product_details summary_item ">
                          {cart.length < 1 &&
                            formatSummaryField("No Item in cart", "")}
                          {cart.length > 0 &&
                            cart.map((item, index) =>
                              formatSummaryField(
                                `${item.quantity}x ${makeTitleCase(
                                  item.product.name
                                )}`,
                                displayAmount(item.total, 2)
                              )
                            )}
                        </div>
                        <div className="subtotal_details summary_item">
                          {formatSummaryField(
                            "Sub Total",
                            displayAmount(cartTotal, 2)
                          )}
                        </div>
                        {coupon && (
                          <div className="coupon_details summary_item">
                            {formatSummaryField(
                              "Coupon Discount",
                              displayAmount(coupon.discountedAmount, 2),
                              true
                            )}
                          </div>
                        )}

                        {deliveryType === "delivery" && (
                          <div className="delivery_details summary_item">
                            {formatSummaryField(
                              "Delivery Fee",
                              displayAmount(formData.deliveryFee, 2),false ,true
                            )}
                          </div>
                        )}
                        {/* <div className="promo_details summary_item"></div> */}
                        <div className="total_details summary_item no-border">
                          {formatSummaryField(
                            "Total",
                            displayAmount(
                              deliveryType === "delivery"
                                ? coupon
                                  ? cartTotal -
                                    coupon?.discountedAmount +
                                    formData.deliveryFee
                                  : cartTotal + formData.deliveryFee
                                : coupon
                                ? cartTotal - coupon?.discountedAmount
                                : cartTotal,
                              2
                            )
                          )}
                        </div>

                        {deliveryType === "delivery" &&
                          (selectedState?.name === "delta" ||
                            selectedState?.name === "abuja" ||
                            selectedState?.name === "imo" ||
                            selectedState?.name === "enugu") && (
                            <Alert key={"deliveryWarning"} variant={"warning"}>
                              <Alert.Heading>Delivery Fee</Alert.Heading>
                              <p className="text-black">
                                You will be charged separately for delivery as
                                the delivery fee in your location fluctuates{" "}
                              </p>
                            </Alert>
                          )}

                        <div
                          className="text-right"
                          style={{ margin: "30px 0 0 0", textAlign: "center" }}
                        >
                          <button
                            className="thm_btn thm_btn-black thm_btn-2"
                            onClick={handleSubmit}
                            disabled={
                              isLoadingRegister ||
                              isLoading ||
                              (isPlaceOrderSuccess && formData.paymentChannel !== 'bani' && !prematureClose)
                            }
                            style={{ width: "100%" }}
                          >
                            {(formData.paymentChannel !== 'bani' && isPlaceOrderSuccess && !prematureClose)
                              ? "Just a sec..."
                              : loadingPayment
                              ? "Loading Payment..."
                              : isLoadingRegister
                              ? "Creating Account..."
                              : isLoading
                              ? "Saving..."
                              : "Make Payment "}
                            {!loadingPayment &&
                              !isLoadingRegister &&
                              !isLoading &&
                              displayAmount(
                                deliveryType === "delivery"
                                  ? coupon
                                    ? cartTotal -
                                      coupon?.discountedAmount +
                                      formData.deliveryFee
                                    : cartTotal + formData.deliveryFee
                                  : coupon
                                  ? cartTotal - coupon?.discountedAmount
                                  : cartTotal,
                                2
                              )}
                          </button>
                        </div>
                        {hasPromo && selectedState?.name === "abuja" && (
                          <p style={{ color: "white", marginTop: 15 }}>
                            Coupon is disabled because one or more item(s) in
                            your cart is already discounted
                          </p>
                        )}
                        {/* {!hasPromo && selectedState?.name === "abuja" && ( */}
                        <div style={{ marginTop: 20 }}>
                          {!coupon && (
                            <h3 style={{ color: "white" }}>
                              Got a coupon code?
                            </h3>
                          )}
                          {!coupon && (
                            <form
                              className="cupon_btn"
                              onSubmit={handleCouponCheck}
                              style={{}}
                            >
                              <input
                                type="text"
                                className="mt-3 mb-3"
                                style={{}}
                                placeholder="Enter code here"
                                value={couponText}
                                onChange={(e) => setCouponText(e.target.value)}
                              />
                              <button
                                type="submit"
                                className="thm_btn thm_btn-white"
                              >
                                {loadingCoupon ? (
                                  <Spinner
                                    size="sm"
                                    variant="danger"
                                    animation="border"
                                  />
                                ) : (
                                  "Apply coupon"
                                )}
                              </button>
                            </form>
                          )}
                        </div>
                        {/* )} */}
                      </div>
                    </div>
                    {/* <div style={{ textAlign: "center", margin: "10px 0" }}>
                      <img
                        style={{ width: "80%" }}
                        src={require("../assets/images/paystack1.png")}
                      />
                      <img
                        style={{ width: "60%" }}
                        src={require("../assets/images/paystack2.png")}
                      />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {(loadingPayment || isLoadingRegister || isLoading || isLoggingOut) && (
        <LoadingModal />
      )}
    </WebsiteLayout>
  );
};

export default Checkout;
