import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { cookieGet } from "./cookie";
import { NAIRA_SIGN } from "../configs/app";
import { toast } from "react-toastify";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const MySwal = withReactContent(Swal);

export const showAlert = ({ title, msg, icon, btnText , callback=false}) =>
  MySwal.fire({
    title,
    text: msg,
    icon,
    confirmButtonText: btnText,
  }).then(val=>{
    if(callback)callback();
  });

export const setHeader = async () => {
  let token = await cookieGet("authToken");
  return {
    headers: {
      token,
    },
  };
};
export const setChowdeckRelayHeader = async () => {
  let token = "sk_live_f5d17d73d20073035bfc902957be577c37af6edce6e52cc18d";
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};
export const setAdminHeader = async () => {
  let token = await cookieGet("adminToken");
  return {
    headers: {
      adminToken:token,
    },
  };
};

export const showToast = (type, msg) => {
  if (type === "success") {
    toast.success(msg, { theme: "colored" });
  } else if (type === "error") {
    toast.error(msg, { theme: "colored" });
  }else if (type === "warning") {
    toast.warning(msg, { theme: "colored" });
  }else if (type === "info") {
    toast.info(msg, { theme: "colored" });
  }
};

export const thousand = (amount) => {
  // convert number to string
  amount = String(amount);
  // return amount string with commas after every three characters
  return amount.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const displayAmount = (amount, pad, sign = NAIRA_SIGN) => {
  // return number string with commas after every three characters
  return `${sign}${thousand(Number(amount).toFixed(pad)).trim()}`;
};

export const makeTitleCase = (str) => {
  if (!str || str.length < 1) return "";
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
};

export function parseTimeDate(date) {
  const dateSplit = date.split("T");
  if (dateSplit.length < 2) {
    return date;
  }
  const time = dateSplit[1].split(":");
  let ampm = "AM";
  let hour = Number(time[0]);
  let minute = Number(time[1]);
  if (hour - 12 >= 0) {
    hour = hour == 12 ? hour : hour - 12;
    ampm = "PM";
  }
  return padNumber(hour) + ":" + padNumber(minute) + ampm;
}
export const random4numbers = () => {
  let rand = Math.floor(Math.random() * 10000);
  return padNumber(rand, 4);
};

export const padNumber = (amount, maxLength = 2) => {
  const num = String(amount);
  if (num.length >= maxLength) {
    return num;
  }
  // add zero to number
  amount = `0${amount.toString().trim()}`;
  if (amount.length < maxLength) {
    return padNumber(amount, maxLength);
  }
  // return amount
  return amount;
};

export const getInitials = function (string) {
  var names = string.split(' '),
      initials = names[0].substring(0, 1).toUpperCase();
  
  if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

export const formatStatus = status => {
  switch(status){
    case 0: return "Unpaid";
    case 1: return "Paid";
    case 2: return "Preparing";
    case 3: return "Ready";
    case 4: return "On Transit";
    case 5: return "Completed";
    case 6: return "Canceled";
    default: return "Unknown"
  }
}

export const formatStatusBadge = status => {
  switch(status){
    case 0: return "secondary";
    case 1: return "primary";
    case 2: return "info";
    case 3: return "warning";
    case 4: return "secondary";
    case 5: return "success";
    case 6: return "danger";
    default: return "dark"
  }
}

export const formatTransactionStatusBadge = status => {
  switch(status){
    case "reported": return "danger";
    case "processing": return "primary";
    case "resolved": return "success";
    default: return "dark"
  }
}

export const formatButtonText = status => {
  switch(status){
    case 0: return "Update order to Paid";
    case 1: return "Update order to being Prepared";
    case 2: return "Update order to Ready";
    case 3: return "Update order to On Transit";
    case 4: return "Update order to Completed";
    case 5: return "Done";
    case 6: return "Done";
    default: return "Done"
  }
}


export const calcCartTotal = (cart) => cart.reduce((n, { total }) => n + total, 0);
export const getCartProducts = (cart) => cart.map(item=>({
  product: item,
  quantity: item.oQuantity,
  isVariable: item?.variable,
  id: item?.variable?`${item?.uid}-${item.oSize.title}`:item?.uid,
  // ...item.product, oQuantity: item.quantity, oSize:item.size, oTotal: item.total
}));

export const customExportToExcel = ({ data, name }) => {
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const ws = XLSX.utils.json_to_sheet(data);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const xdata = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(xdata, name + fileExtension);
}

export const formatDate = date => {
  let d = new Date(date);
  let nth = function(d) {
    if(d>3 && d<21) return 'th';
    switch (d % 10) {
      case 1:  return "st";
      case 2:  return "nd";
      case 3:  return "rd";
      default: return "th";
    }
  }
  let days = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
  let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
  return days[d.getDay()] + ' ' + d.getDate() + nth(d.getDate()) + ' ' + months[d.getMonth()];
}


export function getColor(alpha = 1) {
  const randomColor = Math.floor(Math.random() * 16777215).toString(16);
  return alpha ? `#${randomColor}${Math.floor(alpha * 255).toString(16)}` : `#${randomColor}`;
}
