import React from 'react'
import AdminLayout from '../../layouts/AdminLayout'
import { DashCard } from './Dashboard'
import { displayAmount } from '../../utils/helpers'
import { useQuery } from '@tanstack/react-query'
import { getVirtualAccountDetails, getWalletBalance } from '../../api/chowdeck_relay.api'
import { useState } from 'react'

const ChowdeckDeliveryDashboard = () => {
    const [walletBalance, setWalletBalance] = useState(0)
    const [accountDetails, setAccountDetails] = useState(null)

    const {data:walletBalanceData, isLoading:loadingWalletBalance} = useQuery({
        queryKey: ['walletBalance'],
        queryFn: getWalletBalance,
        onSuccess: data => {
            if(data?.status === "success"){
                setWalletBalance(data?.data?.balance/100)
            }
        }
    }) 

    const {data:getVirtualAccount, isLoading:loadingVirtualAccount} = useQuery({
        queryKey: ['virtualAccount'],
        queryFn: getVirtualAccountDetails,
        onSuccess: data => {
            if(data?.status === "success"){
                setAccountDetails(data?.data)
            }
        }
    }) 
  return (
    <AdminLayout>
         <div className="container-fluid">

            <div className="d-sm-flex align-items-center justify-content-between mb-4" >
            <h1 className="h3 mb-0 text-gray-800"> Chowdeck Delivery</h1>
            </div>


              <div className="row">
                      <DashCard
                        title="Wallet Balance"
                        loading={loadingWalletBalance}
                        value={displayAmount(walletBalance|| 0, 2)}
                        icon="fa-coins"
                      />
                    </div>
                <div>
                {accountDetails && !loadingVirtualAccount &&
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <h4 className="m-0 font-weight-bold text-primary">Virtual Account Details</h4>
                    </div>
                    <div className="card-body">
                        <div className="alert alert-warning">
                            To top up your wallet, make a transfer to the account details below
                        </div>
                        <div className="row">
                            <div className="col-md-6 text-black">
                                <p>Account Number: <strong>{accountDetails?.account_number}</strong></p>
                                <p>Account Name: <strong>{accountDetails?.account_name} </strong></p>
                                <p>Bank Name:<strong>{accountDetails?.bank_name} </strong></p>
                            </div>
                        </div>
                    </div>
                </div>}

                </div>

         </div>
    </AdminLayout>
  )
}

export default ChowdeckDeliveryDashboard