import React, { useState, useEffect } from "react";
import AdminLayout from "../../layouts/AdminLayout";
import { getToken } from "firebase/messaging";
import { storeNotifToken } from "../../api/app.api";
import { messaging } from "../../utils/firebase";
import { fetchCustomerPurchaseHistory, fetchReport, getOutletEarnings, getOutlets, storeAdminNotifToken } from "../../api/admin.api";
import { useSelector } from "react-redux";
import { displayAmount, formatDate, getColor, makeTitleCase, showAlert, thousand } from "../../utils/helpers";
import { Form, Spinner } from "react-bootstrap";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import DateRangePicker from "../../components/Admin/DateRangePicker";
// import CsvDownloadButton from 'react-json-to-csv'

import {
  Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
} from 'chart.js';
import { Doughnut, Bar, Line } from 'react-chartjs-2';
import { API_URL, SITE_URL } from "../../configs/app";

ChartJS.register(ArcElement, CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend);

const Dashboard = () => {
  const [successful, setSuccessful] = useState(false);
  const [period, setPeriod] = useState("today");
  const [isSavingToken, setIsSavingToken] = useState(false);
  const [topProductsFilter, setTopProductsFilter] = useState("sales")
  const [reportJson, setReportJson] = useState(null)
  const [salesData, setSalesData] = useState({
    totalSales:0,
    orderCount:0,
    averageSpend:0,
    itemsCount:0
  })
  const [showItemsSold, setShowItemsSold] = useState(true);
  const [userPhone, setUserPhone] = useState("");
  const [deliveryTypeValues, setDeliveryTypeValues] = useState([0, 0]);
  const [meansOfOrderingValues, setMeansOfOrderingValues] = useState([0,0,0])
  const [frequentCustomersValues, setFrequentCustomersValues] = useState({
    names: [],
    value: []
  });
  const [payingCustomersValues, setPayingCustomersValues] = useState({
    names:[],
    value:[]
  });
 const [frequentFoodNotPizzaCustomers, setFrequentFoodNotPizzaCustomers] = useState({
  names:[],
  value:[],
  customers:[]
 })
 const [frequentPizzaNotFoodCustomers, setFrequentPizzaNotFoodCustomers] = useState({
  names:[],
  value:[],
  customers:[]
 })
 const [oneTimeCustomers, setOneTimeCustomers] = useState({
  names:[],
  value:[]
 })
 const [weeklyCustomers, setWeeklyCustomers] = useState({
  names:[],
  value:[],
  customers:[]
 })
  const [outletSalesValues, setOutletSalesValues] = useState({
    names:[],
    value:[]
  });
  const [dailySalesValues, setDailySalesValues] = useState({
    dates:[],
    orderCount:[],
    totalSales:[]
  });

  const [productCategorySalesValues, setProductCategorySalesValues] = useState({
    productsCategory:[],
    orderCount:[],
    totalSales:[]
  });
  const [topProductsByAmountValues, setTopProductsByAmountValues] = useState({
    names:[],
    value:[]
  });
  const [topProductsByCountValues, setTopProductsByCountValues] = useState({
    names:[],
    value:[]
  });

  const [showRangePicker, setShowRangePicker] = useState(false)
  const [itemsToShow, setItemsToShow] = useState(5);
  

  const VAPIDKEY =
    "BJqB5jzuV_MklnwVr4d0oqtHJb4C88vjofCaM6IwmQXH1Nh3vtNBIzS2p-Dgno8Snng-L8eninZY_tWed3oBE4c";
  const { details } = useSelector((state) => state.admin);
  const [currentId, setCurrentId] = useState(details?._id)
  const [currentName, setCurrentName] = useState(details?.name)
  const [outlets, setOutlets] = useState([])
  const [datePeriodRange, setDatePeriodRange] = useState(null)
  const getMessagingToken = async () => {
    if (isSavingToken) return;
    try {
      setIsSavingToken(true);
      const currentToken = await getToken(messaging, { vapidKey: VAPIDKEY });
      if (currentToken) {
        // console.log("currentToken", currentToken);
        //send to server
        const resp = await storeAdminNotifToken({
          token: currentToken,
          outlet: details?._id,
        });
        setIsSavingToken(false);
      } else {
        setIsSavingToken(false);
        // Show permission request UI
        console.log(
          "No registration token available. Request permission to generate one."
        );
        // ...
      }
    } catch (err) {
      setIsSavingToken(false);
      console.log("An error occurred while retrieving token. ", err);
    }
  };

  // const getUniqueProducts = (array) => {
  //   const result = [];
  //   const map = new Map();
  //   for (const item of array) {
  //     if (!map.has(item.name)) {
  //       map.set(item.name, true); // set any value to Map
  //       result.push({ ...item, count: 1 * item.oQuantity });
  //     } else {
  //       let cItem = result.find((x) => x.name == item.name);
  //       let updatedItem = {
  //         ...cItem,
  //         count: cItem?.count + 1 * item.oQuantity,
  //       };
  //       let itemPosition = result.indexOf(cItem);
  //       result.splice(itemPosition, 1, updatedItem);
  //     }
  //   }
  //   return result;
  // };

  const formatChartOption = ({axis="x", position="top", title, data}) => {
    return {
      indexAxis: axis,
      responsive: true,
      plugins: {
        legend: {
          position: position,
        },
        title: {
          display: true,
          text: title //'Top Frequent Customers',
        },
      },
      onClick: (event, elements) => {
        if (elements.length > 0) {
          const index = elements[0].index;
          if(!data) return;
         if(data[index].phone){
          window.open(`${SITE_URL}/admin/user-profile?phone=${data[index]?.phone}`, '_blank');
         } else{
          showAlert({
            title: "Oops!",
            msg: "No Phone number found",
            icon: "warning",
            btnText: "Close",
          });
         }
          // window.alert(index)
        }
      },
    }
  }
useQuery(['stores'], getOutlets, {
    onSuccess: data => {
      if (!data?.status) {
        return showAlert({
          title: "Oops!",
          msg: typeof data.errors[0].msg == "string"
            ? data.errors[0].msg
            : data.errors[0].msg.message,
          icon: "error",
          btnText: "Close",
        });
      }
      // console.log(data.value)
      setOutlets(data?.value)

    },
    onError: err => showAlert({
      title: "Error!",
      msg: "An internal error has occurred",
      icon: "error",
      btnText: "Close",
    })
  })


  useEffect(() => {
    if (!isSavingToken) getMessagingToken();
  }, [successful]);

  useEffect(() => {
    if(userPhone && userPhone.length > 0){getPurchaseHistory()};
  }, [userPhone])
  


  const handleDateRangeChange = (range) => {
    setDatePeriodRange(range)
  }

  const handlePeriodChange = (e) => {
    if(e.target.value === "date"){
      setShowRangePicker(true)
      return;
    }
    setSalesData({
      totalSales:0,
      orderCount:0,
      averageSpend:0,
      itemsCount:0
    })
    setPeriod(e.target.value);
  };
  const handleOutletChange = (e) => {
    setSalesData({
      totalSales:0,
      orderCount:0,
      averageSpend:0,
      itemsCount:0
    })
    setCurrentId(e.target.value)
  };

  //Get report 
  const {isLoading, isFetching} = useQuery(['report', currentId, datePeriodRange?datePeriodRange:period], fetchReport, {
    onSuccess: (data) => {
      // console.log((data));
      if (!data?.status) {
        return showAlert({
          title: "Oops!",
          msg: typeof data.errors[0].msg == "string"
            ? data.errors[0].msg
            : data.errors[0].msg.message,
          icon: "error",
          btnText: "Close",
        });
      }
      // console.log((data.value));
      if (data.status) {
       
        // console.clear()
        // console.log(data.value)
        setDeliveryTypeValues([data?.value?.pickupAndDelivery?.delivery, data?.value?.pickupAndDelivery?.pickup])
        setMeansOfOrderingValues([data?.value?.meansOfOrdering?.mobile, data?.value?.meansOfOrdering?.website, data?.value?.meansOfOrdering?.others])
        let topFrequent = data.value.topFrequentCustomers
        let frequentNames = topFrequent.map(item => item.customer.name)
        let frequentCount = topFrequent.map(item => item.orderCount)
        setFrequentCustomersValues({
          names:frequentNames, value: frequentCount
        })


        let topPaying = data.value.topPayingCustomers
        let payingNames = topPaying.map(item => item.customer.name)
        let payingCount = topPaying.map(item => item.totalSpent)
        setPayingCustomersValues({
          names:payingNames, value:payingCount,
        })

        let frequentFoodNotPizza = data.value.frequentNonPizzaCustomers
        if(frequentFoodNotPizza.length > 0){
          let frequentFoodNotPizzaCx = frequentFoodNotPizza.map(item => item.customer)
          let frequentFoodNotPizzaNames = frequentFoodNotPizza.map(item => item.customer.name)
          let frequentFoodNotPizzaCount = frequentFoodNotPizza.map(item => item.orderCount)
          setFrequentFoodNotPizzaCustomers({
            names:frequentFoodNotPizzaNames, value:frequentFoodNotPizzaCount, customers:frequentFoodNotPizzaCx
          })
        }
       

        let frequentPizzaNotFood = data.value.frequentPizzaCustomers
        if(frequentPizzaNotFood.length > 0){
          let frequentPizzaNotFoodCx = frequentPizzaNotFood.map(item => item.customer)
          let frequentPizzaNotFoodNames = frequentPizzaNotFood.map(item => item.customer.name)
          let frequentPizzaNotFoodCount = frequentPizzaNotFood.map(item => item.orderCount)
          setFrequentPizzaNotFoodCustomers({
            names:frequentPizzaNotFoodNames, value:frequentPizzaNotFoodCount, customers: frequentPizzaNotFoodCx
          })
        }
       

        let oneTimeCx = data.value.oneTimePurchaseCustomers
        if(oneTimeCx.length > 0){
          let oneTimeCxx = oneTimeCx.map(item => item.customer)
          let oneTimeCxNames = oneTimeCx.map(item => item.customer.name)
          let oneTimeCxCount = oneTimeCx.map(item => item.orderCount)
          setOneTimeCustomers({
            names:oneTimeCxNames, value:oneTimeCxCount, customers: oneTimeCxx
          })
        }

        let weeklyCx = data.value.oneWeeklyPurchaseCustomers
        if(weeklyCx.length > 0){
          let weeklyCxx = weeklyCx.map(item => item.customer)
          let weeklyCxNames = weeklyCx.map(item => item.customer.name)
          let weeklyCxCount = weeklyCx.map(item => item.orderCount)
          setWeeklyCustomers({
            names:weeklyCxNames, value:weeklyCxCount, customers: weeklyCxx
          })
        }

        let outletSales = data.value.outletSales;
        let dates = outletSales.map(item => formatDate(item.date));        
        let orderCount = outletSales.map(item => item.orderCount);
        let totalSales = outletSales.map(item => item.totalSales);
        setDailySalesValues({
          dates, orderCount, totalSales
        })
  
        
        let productCategorySales = data.value.salesByProductCategory;
        productCategorySales = productCategorySales.filter(val=>val.category !== "all-products")
        productCategorySales.sort((a, b) => b.orderQuantity - a.orderQuantity);
        let productCategoryName = productCategorySales.map(item =>`${makeTitleCase(item.category)} - ${thousand(item.orderQuantity)} - ${displayAmount(item.totalSales)}`);        
        let productCategoryCount = productCategorySales.map(item => item.orderQuantity);
        let productCategoryTotalSales = productCategorySales.map(item => item.totalSales);
        setProductCategorySalesValues({
          productsCategory:productCategoryName, orderCount:productCategoryCount, totalSales:productCategoryTotalSales
        })

        // highestProductByAmount
        let topProductsAmount = data.value.highestProductByAmount;
        topProductsAmount = topProductsAmount.slice(0, 20);
        let topProdAmountItems = topProductsAmount.map(item =>`${item.item} - ${displayAmount( item.totalAmountSold)}`);        
        let toProductSoldAmount = topProductsAmount.map(item => item.totalAmountSold);
        setTopProductsByAmountValues({
          names: topProdAmountItems, value: toProductSoldAmount
        })
        // highestProductByQuantity
        let topProductsQuantity = data.value.highestProductByQuantity;
        topProductsQuantity = topProductsQuantity.slice(0, 20);
        let topProductQuantityItems = topProductsQuantity.map(item =>`${item.item} - ${thousand(item.totalQuantitySold)}`);        
        let topProductQuantitySold = topProductsQuantity.map(item => item.totalQuantitySold);
        setTopProductsByCountValues({
          names: topProductQuantityItems, value: topProductQuantitySold
        })

        if(currentId === "6363d70f563e316cd40f12e0")
        {
          if(data.value.outletData.length > 0) {
        let outletData  = data?.value?.outletData[0]
        let outletSalesData = outletData.salesByOutlet
        let sortedOutletData = Object.fromEntries(Object.entries(outletSalesData).sort(([,a],[,b]) => b-a));
        let outletNames = Object.keys(sortedOutletData);
        let outletTotal = Object.values(sortedOutletData);
        setOutletSalesValues({
          names:outletNames, value: outletTotal
        })
        setSalesData(prev=>({
          ...prev,
          totalSales:outletData.totalSales,
          orderCount:outletData.totalOrders          ,
          averageSpend: Math.round(outletData.totalSales/outletData.totalOrders)
        }))
      }
      }else{
        let outletData = data.value.outletData;
        let totalSalesSum = outletData.reduce((sum, item) => sum + item.totalSales, 0);
        let orderCountSum = outletData.reduce((sum, item) => sum + item.orderCount, 0);
        setSalesData(prev=>({
          ...prev,
          totalSales:totalSalesSum,
          orderCount:orderCountSum          ,
          averageSpend: Math.round(totalSalesSum/orderCountSum)
        }))
      }
  
      }
    },
    onError: (error) => {
      showAlert({
        title: "Error!",
        msg: "An internal error has occurred",
        icon: "error",
        btnText: "Close",
      });
    }
  });

  const {mutate:getPurchaseHistory} = useMutation(fetchCustomerPurchaseHistory, {
    onError: (error) => {
      showAlert({
        title: "Error!",
        msg: "An internal error has occurred",
        icon: "error",
        btnText: "Close",
      });
    },
    onSuccess: data => {
      console.log(data)
    },
  })

  // const {refetch:getPurchaseHistory} = useQuery({
  //   enabled:false,
  //   queryKey:['getPurchaseHistory', userPhone],
  //   queryFn: fetchCustomerPurchaseHistory,
  //   onSuccess: data => {
  //     console.log(data)
  //   },
  //   onError: (error) => {
  //     showAlert({
  //       title: "Error!",
  //       msg: "An internal error has occurred",
  //       icon: "error",
  //       btnText: "Close",
  //     });
  //   }
  // })

  const deliveryTypeData = {
    labels: [`Delivery (${deliveryTypeValues[0]})`, `Pick-Up (${deliveryTypeValues[1]})`,],
    datasets: [
      {
        data: deliveryTypeValues,
        backgroundColor: [
          getColor(0.7),
          getColor(0.7),
        ],
      },
    ],
  };
  const meansOfOrderingData = {
    labels: [`Mobile App (${meansOfOrderingValues[0]?.orderCount} - ${displayAmount(meansOfOrderingValues[0]?.totalSale || 0)})`, `Website (${meansOfOrderingValues[1]?.orderCount} - ${displayAmount(meansOfOrderingValues[1]?.totalSale || 0)})`, `Others (${meansOfOrderingValues[2]?.orderCount} - ${displayAmount(meansOfOrderingValues[2]?.totalSale || 0)})`],
    datasets: [
      {
        data: [meansOfOrderingValues[0]?.orderCount, meansOfOrderingValues[1]?.orderCount, meansOfOrderingValues[2]?.orderCount],
        backgroundColor: [
          getColor(0.7),
          getColor(0.7),
          getColor(0.7),
        ],
      },
    ],
  };
  const topFrequentCustomersData = {
    labels: frequentCustomersValues.names,
    datasets: [
      {
        label: 'number of purchases',
        data: frequentCustomersValues.value,
        backgroundColor:  getColor(0.7),
      },
    ],
  };
  const topPayingCustomersData = {
    labels: payingCustomersValues.names,
    datasets: [
      {
        label: 'total spend in ₦',
        data: payingCustomersValues.value,
        backgroundColor: getColor(0.7),
      },
    ],
  };
  const outletSalesData = {
    labels: outletSalesValues.names,
    datasets: [
      {
        label: 'Outlet sales in ₦',
        data: outletSalesValues.value,
        backgroundColor:  getColor(0.7),
      }
    ],
  };

  let color1 = getColor(0.7)
  let color2 = getColor(0.7)
 const dailySalesData =  {
  labels: dailySalesValues.dates,
  datasets: [
    {
      label: 'Sales',
      data: dailySalesValues.totalSales,
      borderColor:  color1,
      backgroundColor: color1,
    },
    {
      label: 'Order count',
      data: dailySalesValues.orderCount,
      borderColor:  color2,
      backgroundColor: color2,
    },
  ],
 };

 const productCategorySalesData =  () => {
  let colorArray =  productCategorySalesValues.totalSales.map(()=>getColor(0.7))
  return {
  labels: productCategorySalesValues.productsCategory,
  datasets: [
    {
      label: 'Order count',
      data: productCategorySalesValues.orderCount,
      backgroundColor: colorArray,
    },
    {
      label: 'Total Sales',
      data: productCategorySalesValues.totalSales,
      backgroundColor:colorArray,
    },
  ],
}
 };

 const productCatOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: 'right',
    },
    title: {
      display: true,
      text: 'Top Product Categories',
    },
  },
  
};

 const topProductOptions = ({title}) => {
  return {
  responsive: true,
  plugins: {
    legend: {
      position: 'right',
    },
    title: {
      display: true,
      text: title,
    },
  },
}
};


const frequentFoodNotPizzaData = {
  labels: frequentFoodNotPizzaCustomers.names,
  datasets: [
    {
      label:'customers that buy only food but not pizza',
      data: frequentFoodNotPizzaCustomers.value,
      backgroundColor:  getColor(0.7),
   }
  ],
};

const frequentPizzaNotFoodData = {
  labels: frequentPizzaNotFoodCustomers.names,
  datasets: [
    {
      label: 'customers that buy only pizza but not food',
      data: frequentPizzaNotFoodCustomers.value,
      backgroundColor:  getColor(0.7),
    }
  ],
};


const oneTimeCustomersData = {
  labels: oneTimeCustomers.names,
  datasets: [
    {
      label: 'customers that have bought only once',
      data: oneTimeCustomers.value,
      backgroundColor:  getColor(0.7),
    }
  ],
};

const weeklyCustomersData = {
  labels: weeklyCustomers.names,
  datasets: [
    {
      label: 'customers who have bought once a week within the period',
      data: weeklyCustomers.value,
      backgroundColor:  getColor(0.7),
    }
  ],
};

const topProductsByAmountData =  {
  labels: topProductsByAmountValues.names,
  datasets: [
    {
      label: 'Top Selling Products',
      data: topProductsByAmountValues.value,
      backgroundColor: topProductsByAmountValues.value.map(()=>getColor(0.7)),
    },
  ],
 };
const topProductsByQuantityData =  {
  labels: topProductsByCountValues.names,
  datasets: [
    {
      label: 'Top Ordered Products',
      data: topProductsByCountValues.value,
      backgroundColor: topProductsByCountValues.value.map(()=>getColor(0.7)),
    },
  ],
 };

useEffect(() => {
  const handleScroll = () => {
    let filterBar = document.querySelector('.d-sm-flex')
    const currentScrollY = window.scrollY;
    if (currentScrollY > 200) {
      filterBar.classList.add('fixedFilter');
    } else {
      filterBar.classList.remove('fixedFilter');
    }
  };

  window.addEventListener("scroll", handleScroll, { passive: true });

  return () => window.removeEventListener("scroll", handleScroll);
}, []);

useEffect(() => {
  setReportJson([
    {outletSalesValues},
    {"total sales":salesData.totalSales, "Order Count":salesData.orderCount, "Average Spend":salesData.averageSpend},
  ])
}, [salesData, outletSalesValues])


const handleDownload = () => {

}
return (
    <AdminLayout>
      {(isLoading || isFetching) && (
        <div className="modal-backdrop show" style={{backgroundColor: 'rgba(0, 0, 0, 1)', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <div className="spinner-border text-danger" role="status" >
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
       )} 
      <div className="container-fluid">
        {/* <div className="d-sm-flex align-items-center justify-content-between mb-4 "> */}

          <div className="d-sm-flex align-items-center justify-content-between mb-4" >
          <h1 className="h3 mb-0 text-gray-800">
            Welcome {makeTitleCase(currentName)}
          </h1>
          <div style={{ display: "flex" }}>
            {details?.username === "ecommerce" &&
              <Form.Group className="mb-3 mr-3" controlId="formSelect">
                <Form.Select
                  aria-label="filter-duration"
                  onChange={handleOutletChange}
                  value={currentId}
                // style={{minHeight:100}}
                >
                  <option selected disabled value="">Filter</option>
                  <option value="6363d70f563e316cd40f12e0">All Outlets</option>
                  {
                    outlets.map((item, index) => <option key={`hdhwr-${Math.floor(Math.random() * 9999999999)}`} value={item?._id}>{makeTitleCase(item?.name)}</option>)
                  }
                </Form.Select>
              </Form.Group>}
            <Form.Group className="mb-3" controlId="formSelect">
              <Form.Select
                aria-label="filter-duration"
                onChange={handlePeriodChange}
                value={period}
              // style={{minHeight:100}}
              >
                <option selected disabled value="">Filter</option>
                <option value="date">Custom Date</option>
                <option value="today">Today</option>
                <option value="yesterday">Yesterday</option>
                <option value="current_week">Current Week</option>
                <option value="last_week">Last Week</option>
                <option value="month">This Month</option>
                <option value="last_month">Last Month</option>
                <option value="all_time">All Time</option>
              </Form.Select>
            </Form.Group>
            {showRangePicker && 
            <Form.Group className="mb-3 ml-3 mr-3" style={{ border: "1px solid #ccc", borderRadius: 5, backgroundColor: "#fff" }} controlId="formInput">
              <DateRangePicker onChange={handleDateRangeChange} />
            </Form.Group>}
            <Form.Group className="ml-3" controlId="formSelect">
            {/* {reportJson?<CsvDownloadButton data={reportJson} />:""} */}
            <button onClick={handleDownload} className="btn btn-success btn-lg"><i className="fa fa-download"></i></button>
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <DashCard
            title="Order Count"
            loading={isLoading | isFetching}
            value={salesData.orderCount || 0}
            icon="fa-clipboard-list"
            details={details}
            period={period}
            outlet={currentId}
            range={datePeriodRange}
          />
          <DashCard
            title="Order Sales"
            loading={isLoading | isFetching}
            details={details}
            period={period}
            outlet={currentId}
            value={displayAmount(salesData.totalSales || 0,2)}
            icon="fa-coins"
            range={datePeriodRange}
          />


          {/* <DashCard
            title="Coupon Sales"
            loading={isLoading}
            details={details}
            period={period}
            outlet={currentId}
            value={displayAmount(earningsData?pageData?.couponRevenue:0,2)}
            icon="fa-coins"
            range={datePeriodRange}
          /> */}

          {/* <DashCard
            title="Discount Given"
            loading={isLoading}
            details={details}
            period={period}
            outlet={currentId}
            value={displayAmount(earningsData?pageData?.discountGiven:0,2)}
            icon="fa-coins"
          /> */}

          <DashCard
            title="Average Spend"
            loading={isLoading | isFetching}
            value={displayAmount(salesData.averageSpend || 0, 2)}
            icon="fa-money-bill"
          />
          {/* <DashCard
            title="Items Sold"
            loading={isLoading}
            value={pageData.items.length}
            icon="fa-shopping-bag"
            soldItems={true}
            changeSold={() => setShowItemsSold(!showItemsSold)}
          /> */}

          {/* <DashCard
            title="Orders with Coupon Code"
            loading={isLoading}
            value={earningsData?pageData?.couponLength:0}
            icon="fa-clipboard-list"
            details={details}
            period={period}
            outlet={currentId}
            range={datePeriodRange}
          /> */}

        </div>

        <div className="row m-2">
            { currentId === "6363d70f563e316cd40f12e0" && 
               <div className="col-lg-12 card shadow mb-4 p-3">
             <Bar options={formatChartOption({position:"top", title:"Top Outlets"})} data={outletSalesData} /> 
             </div>}
           <div className="col-lg-12 card shadow mb-4 p-3">

              <Line options={formatChartOption({position:"top", title:"Daily Sales"})} data={dailySalesData} />
          </div>
        </div>

        <div className="row m-2">
          <div className="col-lg-5 card shadow mb-4 p-3">
            <Bar options={formatChartOption({axis:"y", position:"bottom", title:"Top Frequent Customers"})} data={topFrequentCustomersData} />
          </div>

          <div className="col-lg-3 card shadow mb-4 p-3 ml-4">
            <Doughnut data={deliveryTypeData} />
          </div>
          <div className="col-lg-3 card shadow mb-4 p-3 ml-4">
            <Doughnut data={meansOfOrderingData} />
          </div>
        </div>

        <div className="row m-2 mr-0 pr-0">
        <div className="col-lg-5  card shadow mb-4 p-3">
        <Doughnut data={productCategorySalesData()} options={productCatOptions} />
        </div>
          <div className="col-lg-7 card shadow mb-4">
            <Bar options={formatChartOption({axis:"y",position:"bottom", title:"Top Paying Customers"})} data={topPayingCustomersData} />
          </div>
        </div>

        <div className="row m-2 mr-0 pr-0">
        <div className="col-lg-6 card shadow mb-4 p-3">
        <Doughnut data={topProductsByAmountData} options={topProductOptions({title:"Top Products By Amount"})} />
        </div>

          <div className="col-lg-6 card shadow mb-4">
          <Doughnut data={topProductsByQuantityData} options={topProductOptions({title:"Top Products By Order Quantity"})} />
           </div>
        </div>

     

        <div className="row m-2">
        <div className="col-lg-12 card shadow mb-4 p-3">
        <Bar options={formatChartOption({position:"top", title:"Weekly Food Customers (Without Pizza)", data:frequentFoodNotPizzaCustomers.customers})} data={frequentFoodNotPizzaData} />
        </div>
          <div className="col-lg-12 card shadow mb-4 p-3">
            <Bar options={formatChartOption({position:"top", title:"Weekly Pizza Customers (Without Food)", data:frequentPizzaNotFoodCustomers.customers})} data={frequentPizzaNotFoodData} />
          </div>
          <div className="col-lg-12 card shadow mb-4 p-3">
            <Bar options={formatChartOption({position:"top", title:"Weekly Customers", data:weeklyCustomers.customers})} data={weeklyCustomersData} />
          </div>
        </div>

        <div className="row">
          <div className="card shadow mb-4 col-lg-12">
            {/* <div className="card-header py-3" style={{ display: "flex", justifyContent: "space-between" }}>
              <h4 className="m-0 font-weight-bold text-default text-center" style={{ cursor: "pointer" }} onClick={() => setShowItemsSold(!showItemsSold)}>Top Products</h4>
              <div style={{ display: "flex", justifyContent: "space-between", }}>
             
                <select className="select border shadow px-3 py-1 mx-2 text-sm" onChange={(e) => setTopProductsFilter(e.target.value)}>
                  <option value="sales">Sales</option>
                  <option value="quantity">Quantity</option>
                </select>
                <select className="select border shadow px-3 py-1 mx-2 text-sm" onChange={(e) => setItemsToShow(e.target.value)}>
                  <option value={null}>Items to show</option>
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                </select>
              </div>
            </div> */}
            {showItemsSold && (
              <div className="card-body">
                <div className="table-responsive">
                  {/* <table
                    className="table table-bordered center text-sm"
                    style={{ textAlign: "center" }}
                    id="dataTable"
                    width="100%"
                    cellSpacing={0}
                  >
                    <thead>
                      <tr className="text-sm">
                        <th>S/N</th>
                        <th>Items</th>
                        <th>Amount</th>
                        <th>Quantity</th>
                        <th>Net Sales</th>
                      </tr>
                    </thead>
                    <tfoot>
                      <tr>
                        <th>S/N</th>
                        <th>Items</th>
                        <th>Amount</th>
                        <th>Quantity</th>
                        <th>Net Sales</th>
                      </tr>
                    </tfoot>

                    <tbody>
                      {pageData?.items?.length <= 0 && <tr style={{ textAlign: "left" }}><td colSpan={5}>No data to display</td></tr>}
                      {pageData?.items?.length > 0 &&
                        pageData?.items?.sort((prev, current) => {
                          if (topProductsFilter === "quantity") {
                            return current.count - prev.count
                          }
                          else {
                            let currentPrice = current?.sale_price && current?.sale_price != null
                              ? current?.sale_price * current.count
                              : current?.product_price * current.count;
                            let prevPrice = prev?.sale_price && prev?.sale_price != null
                              ? prev?.sale_price * prev.count
                              : prev?.product_price * prev.count;
                            return currentPrice - prevPrice
                          }
                        }).map((item, index) => {
                          let price = item?.sale_price && item?.sale_price != null
                            ? item?.sale_price
                            : item?.product_price;
                          if (index < itemsToShow) {
                            return (
                              <tr key={`hfhf-${Math.floor(Math.random() * 99999999999)}`} className="text-sm">
                                <td>{index + 1}</td>
                                <td style={{ textAlign: "left" }}>
                                  <img
                              src={item.image_url}
                              style={{ width: 35, height: 35 }}
                            />{" "}
                                  {makeTitleCase(item.name)}
                                </td>
                                <td>{displayAmount(price)} </td>
                                <td>{item.count}</td>
                                <td>{displayAmount(price * item.count)}</td>
                              </tr>)
                          }
                        }
                        )}
                    </tbody>
                  </table> */}
                </div>
              </div>
            )}
          </div>
        </div>


      </div>
    </AdminLayout>
  );
};

export default Dashboard;

export const DashCard = ({
  title,
  value,
  icon,
  loading,
  details,
  period,
  outlet,
  soldItems,
  changeSold,
  range,
}) => (
  <div className="col-xl-4 col-md-6 mb-3">
    <div className="card border-left-primary shadow h-100 py-2">
      <div className="card-body">
        <Link
          onClick={(e) => {
            if (soldItems) {
              e.preventDefault();
              changeSold();
            }
          }}
          to={
            details
              ? details?.username === "ecommerce"
                ? `/admin/all-outlet-orders?period=${period}&oid=${outlet}&range=${range ? JSON.stringify(range) : null}`
                : `/admin/customer-orders?period=${period}`
              : ""
          }
        >
          <div className="row no-gutters align-items-center">
            <div className="col mr-2">
              {!loading ? (
                <>
                  <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                    {title}
                  </div>
                  <div className="h5 mb-0 font-weight-bold text-gray-800">
                    {value}
                  </div>
                </>
              ) : (
                <Spinner animation="border" size="md" color="#8e8e8e" />
              )}
            </div>
            <div className="col-auto">
              <i className={`fas ${icon} fa-2x text-gray-300`} />
            </div>
          </div>
        </Link>
      </div>
    </div>
  </div>
);
