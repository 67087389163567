import React from "react";

const MobileApp = () => {
  return (
    <section className="cta_area mobile_app pt-220 pb-120">
      <div className="container">
        <div className="cta_bg_wrap">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-5">
              <div className="cta_app" style={{bottom:-210}}>
                <img 
                // src="assets/img/bg/frame1.png" alt="" 
                src="assets/img/bg/cta_app.png" alt="" 
                style={{width:"70%"}} 
                />
              </div>
            </div>
            <div className="col-lg-7 col-md-7">
              <div className="cta_text">
                <div className="sec_title">
                <span>Download our app</span>
                  <h2> Order <span className="text-danger">on the Go!</span></h2>
                  {/* <span>off your next purchase</span> */}
                </div>

                {/* <div className="hero_btn">
            <a className="thm_btn" href="http://thegenesisvibe.com" target="_blank">
              Invite someone
            </a>
          </div> */}
                <ul className="cta_app_wrap ul_li">
                  <li>
                    <a href="https://apps.apple.com/us/app/genesis-restaurant/id1671403114" target="_blank">
                      <img src="assets/img/icon/app_store.png" alt />
                    </a>
                  </li>
                  <li>
                    <a href="https://play.google.com/store/apps/details?id=com.genesis_restaurant" target="_blank">
                      <img src="assets/img/icon/play_store.png" alt />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MobileApp;
