import React from "react";
import OwlCarousel from "react-owl-carousel";
import { Link } from "react-router-dom";
import useWindowDimensions from "../useWindowDimensions";
import JumbotronSliderImage from "./JumbotronSliderImage";
import JumbotronSliderText from "./JumbotronSliderText";

const HomeSection1 = () => {
  const { height, width } = useWindowDimensions();
  return (
    <section className="hero_area hero_1">
      <div className="hero_wrap hero_height hero_bg">
        <div className="container-fluid">
          <OwlCarousel className="" style={{
            // width:"80%",
             margin:"auto"}}  
             items={1} dots={true} loop autoplay autoplayHoverPause >
            {/* {(width > 767) &&<JumbotronSliderText
              title={"Are you Hungry?"}
              subtitle="We serve great meals here, Let's fill your stomach with fresh and tasty meals you'll love"
            />} */}

            {/* <JumbotronSliderImage image={require("../../assets/images/fridayfrenzyph.webp")}/>
            <JumbotronSliderImage image={require("../../assets/images/fridayfrenzyabuja.webp")}/>
            <JumbotronSliderImage image={require("../../assets/images/fridayfrenzyasaba.webp")}/>
            <JumbotronSliderImage image={require("../../assets/images/fridayfrenzyenugu.webp")}/> */}

            <JumbotronSliderImage image={require("../../assets/images/ram_banner.webp")}/>
            <JumbotronSliderImage image={require("../../assets/images/banner3.webp")}/>
            <JumbotronSliderImage image={require("../../assets/images/banner2.webp")}/>
            <JumbotronSliderImage image={require("../../assets/images/banner1.webp")}/>
            <JumbotronSliderImage image={require("../../assets/images/banner6.webp")}/>
          </OwlCarousel>


          {(width <= 767) && <div className="hero_btn text-center"><Link className="thm_btn" to="/order"> Order now </Link></div>} 
        </div>
      </div>

    </section>
  );
};

export default HomeSection1;
