import React, { useState } from "react";
import {
  Modal,
  Badge,
  Row,
  Col,
  Card,
  ListGroup,
  Button,
  Table,
  Form,
} from "react-bootstrap";
import moment from "moment";
import {
  displayAmount,
  formatButtonText,
  formatStatus,
  formatStatusBadge,
  makeTitleCase,
  showAlert,
} from "../../utils/helpers";
import { useSelector } from "react-redux";
import { updateOrder } from "../../api/admin.api";
import { useMutation } from "@tanstack/react-query";

const ViewOrderModal = ({ show, close, order }) => {
  const [newStatus, setNewStatus] = useState(order.orderStatus);
  const { details } = useSelector((state) => state.admin);
  let isAgent = details?.username == "ecommerce" ? true : false;
  // console.log(order?.couponPromo)

  const { mutate, isLoading } = useMutation(updateOrder, {
      onSuccess: (data) => {
        if (data.status) {
          showAlert({
            title: "Updated!",
            msg: `Order status has been updated`,
            icon: "success",
            btnText: "Close",
          });
          close();
        }
      },
      onError: (err) => {
        showAlert({
            title: "Error!",
            msg: `An internal error has occured`,
            icon: "error",
            btnText: "Close",
          });
        console.log(err);
      }
    });

  const handleSubmit = async (type) => {
    let conf = window.confirm(
      "Are you sure you want to update the status of this order?"
    );
    if (!conf) return;
    setNewStatus(type);
    mutate({ type, id: order._id });
  };

  return (
    <Modal
      show={show}
      onHide={() => close(newStatus !== order?.orderStatus ? true : false)}
      keyboard={false}
      size={"lg"}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Order #{order?.orderNumber}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row style={{ marginTop: 10, marginBottom: 10 }}>
          <Col
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span> Created on:</span>
            <span style={{ fontWeight: "bold" }}>
              {moment(order?.created).format("MMM Do YYYY, h:mm A")}
            </span>
          </Col>

          <Col
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span>Order Status:</span>
            <span style={{ fontWeight: "bold" }}>
              <Badge bg={formatStatusBadge(order?.orderStatus)}>
                {makeTitleCase(formatStatus(order?.orderStatus))}
              </Badge>
            </span>
          </Col>
          <Col
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span>Outlet:</span>
            <span style={{ fontWeight: "bold" }}>{order?.outlet?.name}</span>
          </Col>
        </Row>

        <Row style={{ marginTop: 10, marginBottom: 10 }}>
          <Col>
            <Card>
              <Card.Body>
                <Card.Title>Customer Information</Card.Title>
                <Card.Text>
                  <Row>
                    <Col>
                      <span>Name: </span>
                      <span
                        style={{
                          fontWeight: "bold",
                          marginLeft: 5,
                          color: "#000",
                        }}
                      >
                        {order?.customer?.name}
                      </span>
                    </Col>
                  </Row>

                  {order?.customer?.email && (
                    <Row>
                      <Col>
                        <span>Email: </span>
                        <span
                          style={{
                            fontWeight: "bold",
                            marginLeft: 5,
                            color: "#000",
                          }}
                        >
                          {order?.customer?.email}
                        </span>
                      </Col>
                    </Row>
                  )}
                  {order?.customer?.phone && (
                    <Row>
                      <Col>
                        <span>Phone: </span>
                        <span
                          style={{
                            fontWeight: "bold",
                            marginLeft: 5,
                            color: "#000",
                          }}
                        >
                          {order?.customer?.phone}
                        </span>
                      </Col>{" "}
                    </Row>
                  )}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card style={{ minHeight: 150 }}>
              <Card.Body>
                <Card.Title>Delivery Information</Card.Title>
                <Card.Text>
                  <Row>
                    <Col>
                      <span>Delivery Method: </span>
                      <span
                        style={{
                          fontWeight: "bold",
                          marginLeft: 5,
                          color: "#000",
                        }}
                      >
                        {makeTitleCase(order?.deliveryType)} {order?.deliveryChannel}
                      </span>
                    </Col>
                  </Row>

                  {order?.deliveryType === "delivery" && order?.deliveryChannel === "chowdeck" && (
                    <Row>
                      <Col>
                        <span>Delivery PIN: </span>
                        <span
                          style={{
                            fontWeight: "bold",
                            marginLeft: 5,
                            color: "#000",
                          }}
                        >
                          {order?.deliveryResponse?.delivery_pin}
                        </span>
                      </Col>
                    </Row>
                  )}
                  {order?.deliveryType === "delivery" && (
                    <Row>
                      <Col>
                        <span>Address: </span>
                        <span
                          style={{
                            fontWeight: "bold",
                            marginLeft: 5,
                            color: "#000",
                          }}
                        >
                          {order?.address}
                           {/* {order?.deliveryArea?.name} */}
                        </span>
                      </Col>
                    </Row>
                  )}

                  {order?.note?.length > 0 && (
                    <Row>
                      <Col>
                        <span>Additional Note: </span>
                        <span
                          style={{
                            fontWeight: "bold",
                            marginLeft: 5,
                            color: "#000",
                          }}
                        >
                          {order?.note}
                        </span>
                      </Col>{" "}
                    </Row>
                  )}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row style={{ marginTop: 10, marginBottom: 10 }}>
          <Col>
            <Table bordered hover>
              <thead>
                <tr>
                  <th>Items</th>
                  <th>Amount</th>
                  <th>Quantity</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {order?.items.map((item) => {
                  let unitPrice = item?.oSize
                    ? item?.oSize?.price
                    : item?.is_promo && item?.sale_price !== null
                    ? item?.sale_price
                    : item?.product_price;
                  return (
                    <tr key={`hbd-${Math.floor(Math.random()*9999)}`}>
                      <td style={{ display: "flex", alignItems: "center" }}>
                        <div
                          style={{
                            width: 40,
                            height: 40,
                            borderRadius: 5,
                            marginRight: 5,
                            backgroundImage: `url(${item?.image_url})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "center",
                          }}
                        ></div>
                        {makeTitleCase(item?.name)}{" "}
                        {item?.oSize ? `(${item?.oSize?.title})` : null}
                      </td>
                      <td>{displayAmount(unitPrice)}</td>
                      <td>{item.oQuantity}</td>
                      <td>{displayAmount(unitPrice * item.oQuantity, 2)}</td>
                    </tr>
                  );
                })}
                <tr>
                  {" "}
                  <td></td> <td></td>
                  <td style={{ textAlign: "right" }}>Sub Total:</td>
                  <td>{displayAmount(order?.cartSum, 2)}</td>
                </tr>

                {order?.couponPromo && (
                  <tr style={{color:"green"}}>
                    {" "}
                    <td></td> <td></td>
                    <td style={{ textAlign: "right" }}>Coupon Discount:</td>
                    <td>- {displayAmount(order?.couponPromo?.discountedAmount, 2)}</td>
                  </tr>
                )}

                {(order?.deliveryType === "delivery" &&
                  order?.deliveryFee > 0) && (
                  <tr>
                    {" "}
                    <td></td> <td></td>
                    <td style={{ textAlign: "right" }}>Delivery Fee:</td>
                    <td>{displayAmount(order?.deliveryFee, 2)}</td>
                  </tr>
                )}

                <tr>
                  {" "}
                  <td></td> <td></td>
                  <td style={{ fontWeight: "bold", textAlign: "right" }}>
                    Total:
                  </td>
                  <td style={{ fontWeight: "bold" }}>
                    {displayAmount(order?.sumTotal, 2)}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>

        <div className="text-center mt-5">
          {isAgent && ((order?.deliveryType === "delivery" && order?.orderStatus >= 1) ||
            (order?.deliveryType === "pickup" && order?.orderStatus >= 1))
            && (
              <Button
                // disabled={isLoading}
                className="btn btn-lg btn-success"
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit(5);
                }}
                type="submit"
              >
                {isLoading ? "Updating":"Mark order as completed"}
              </Button>
            )}

          {!isAgent && ((order?.deliveryType === "delivery" && (order?.orderStatus === 1 || order?.orderStatus === 2 || order?.orderStatus === 3)) ||
            (order?.deliveryType === "pickup" && (order?.orderStatus === 1 || order?.orderStatus === 2))) &&  (
            <Button
                disabled={isLoading}
              className={`btn btn-lg btn-${formatStatusBadge(order?.orderStatus + 1)}`}
              onClick={(e) => {
                e.preventDefault();
                handleSubmit(order?.orderStatus + 1 );
              }}
              type="submit"
            >
              { isLoading ? "Updating": formatButtonText(order?.orderStatus) }
            </Button>
          )}

        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ViewOrderModal;
