import React, {useState, useEffect } from "react";
import MenuItemGrid from "../components/WebComponents/MenuItemGrid";
import WebsiteLayout from "../layouts/WebsiteLayout";
import { displayAmount, makeTitleCase, showAlert } from "../utils/helpers";
import OwlCarousel from "react-owl-carousel";
import InitialOrderChoice from "../components/Modals/InitialOrderChoice";
import { useQuery } from "@tanstack/react-query";
import { getProductCategories } from "../api/app.api";
import { useDispatch, useSelector } from "react-redux";
import { setProductCategories, toggleMobileCart, toggleWebCart } from "../store/slices/appSlice";
import { setCart, setSelectedCategory, setSelectedOutlet, setSelectedState } from "../store/slices/orderSlice";
import { getProductsByOutlet } from "../api/product.api";
import MenuItemSkeleton from "../components/Skeletons/MenuItemSkeleton";
import { useNavigate, useParams } from "react-router-dom";
import { Search, X } from "react-feather";
import { Form } from "react-bootstrap";
import useWindowDimensions from "../components/useWindowDimensions";
import { checkStoreStatus } from "../api/order.api";

const Menu = () => {
  let dispatch = useDispatch();
  let history = useNavigate();
  const {width} = useWindowDimensions()
  const {category} = useParams();
  const [toggleSearch, setToggleSearch] = useState(false)
  const [allProducts, setAllProducts] = useState([])
  const [currentProducts, setCurrentProducts] = useState([])
  const { productCategories, showInitialOrder } = useSelector(
    (state) => state.app
  );
  const { selectedCategory, selectedState, selectedOutlet, deliveryType } =
    useSelector((state) => state.order);
  const {
    data: categoriesData,
    isLoading: isLoadingCategories,
    isError: isErrorCategories,
    isSuccess: isSuccessCategories,
    refetch,
  } = useQuery(["productCategories"], getProductCategories, {
    enabled: false,
  });

  const {
    data: productData,
    isLoading: isLoadingProduct,
    isError: isErrorProducts,
    isSuccess: isSuccessProducts,
    refetch: refetchProductData,
  } = useQuery(
    [
      "products",
      {
        category: selectedCategory,
        state: selectedState,
        outlet: selectedOutlet,
        deliveryType,
      },
    ],
    getProductsByOutlet,
    { enabled: false,
    onSuccess: data => {
      if(!data.status){
        return showAlert({
          title: "Oops!",
          msg: typeof data.errors[0].msg == "string"
          ? data.errors[0].msg
          : data.errors[0].msg.message,
          icon: "error",
          btnText: "Close",
        });
      }
     return setCurrentProducts(data?.value)
    },
    onError: err => {
      return  showAlert({
        title: "Error!",
        msg: "An internal error has occurred",
        icon: "error",
        btnText: "Close",
      })
    }
    }
  );

  const {
    data: allProductData,
    isLoading: isLoadingAllProduct,
    isError: isErrorAllProducts,
    isSuccess: isSuccessAllProducts,
    refetch: refetchAllProductData,
  } = useQuery(
    [
      "products",
      {
        category: "all-products",
        state: selectedState,
        outlet: selectedOutlet,
        deliveryType,
      },
    ],
    getProductsByOutlet,
    { enabled: false,
      onSuccess: data => {
        if(data.status){
            setAllProducts(data?.value)
        }
      },
      onError: err => {
        return  showAlert({
          title: "Error!",
          msg: "An internal error has occurred",
          icon: "error",
          btnText: "Close",
        })
      }
    }
  );

  const {isLoading:isCheckingOutletStatus, refetch:checkIfStoreIsOpen} = useQuery(['store', selectedOutlet], checkStoreStatus, {
    enabled:false,
    onSuccess: data => {
      // console.log(data)
      if (!data?.status) {
          return showAlert({
              title: "Oops!",
              msg: typeof data.errors[0].msg == "string"
              ? data.errors[0].msg
              : data.errors[0].msg.message,
              icon: "error",
              btnText: "Close",
            });
        }
       if(data?.value?.closed) return  showAlert({
          title: "Oops!",
          // msg: `We currently do not have riders for delivery, You can select pickup option to pick up your item at the outlet`,
          msg: `${data?.value?.name} is currently closed for delivery. You can make a purchase and pickup at the outlet`,
          icon: "warning",
          btnText: "Continue",
          callback: selectOutlet,
        })
},
    onError: err => {
      return  showAlert({
        title: "Error!",
        msg: "An internal error has occurred",
        icon: "error",
        btnText: "Close",
      })
    }
  } )

  const handleSearch = e => {
      e.preventDefault();
      let searchedValue = e.target.value.toLowerCase()
      let searchedItems = allProducts.filter(item=>
        item?.name?.toLowerCase().includes(searchedValue)
        || item?.category.includes(searchedValue) 
        || item?.product_price == searchedValue
      )
     return setCurrentProducts(searchedItems)
  }

  useEffect(() => {
    if (!productCategories) refetch();
  }, [productCategories]);

  useEffect(() => {
    if (categoriesData) {
      dispatch(setProductCategories(categoriesData.value));
      dispatch(setSelectedCategory(categoriesData.value[0].slug));
    }
  }, [isSuccessCategories]);

  useEffect(() => {
    if (selectedCategory && selectedState && selectedOutlet) {
      refetchProductData();
    }
  }, [selectedCategory, selectedState, selectedOutlet]);
  useEffect(() => {
    if (
      selectedOutlet
      && deliveryType === "delivery"
      ) {
      checkIfStoreIsOpen();
    }
  }, [selectedOutlet]);

  const handleCategoryChange = (cat) => {
    setToggleSearch(false)
    history(`/menu/${cat.slug}`)
    // dispatch(setSelectedCategory(cat.slug));
  };
  useEffect(() => {
    if(category)dispatch(setSelectedCategory(category !== "all"?category:"all-products"))
  }, [category])
  

  const selectOutlet = () => {
      dispatch(setSelectedOutlet(null))
      dispatch(setSelectedState(null))
      dispatch(setCart([]));
  }
  return (
    <WebsiteLayout>
      <section className="shop_area pt-120 pb-120">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div
                className="masonry_active mb-40"
                style={{
                  flexWrap: "unset",
                  justifyContent: "flex-start",
                  alignItems: "unset",
                  overflow: "scroll",
                  overflowY: "hidden",
                }}
              >
                <button
                      onClick={() =>{setToggleSearch(!toggleSearch); refetchAllProductData()}}
                      key={`searchicon`}
                      className=""
                      style={{
                        borderRadius:toggleSearch?"50% 0 0 50%":"50%",
                        padding:"13px 16px",
                        marginRight:toggleSearch?0:15
                    }}
                    >
                      {toggleSearch?<X size={18}/>:<Search size={18}/>}
                    </button>

                  {toggleSearch && <Form.Control
                  onChange={handleSearch}
                    type="search"
                    autoFocus
                    id="menusearch"
                    className=""
                    placeholder="search..."
                    style={{
                      width:width<767?120:150, 
                      alignSelf:"center", 
                      margin:"-20px 15px 0 0",
                      borderWidth:0,
                      borderRadius:"0 25px 25px 0",
                      outline: "none",
                      boxShadow:"0 0 0 0 rgb(13 110 253 / 25%)",
                      padding:width<767?"22.7px 15px":"25px 15px",                     
                    }}
                    />}

                {productCategories &&
                  productCategories?.map((item, index) => (
                    <button
                      onClick={() => handleCategoryChange(item)}
                      key={`dbe-${Math.floor(Math.random()*9999)}-${index}`}
                      value={item.slug}
                      className={selectedCategory === item.slug ? "active" : ""}
                      style={{borderRadius:120}}
                    >
                      {makeTitleCase(item.name)}
                    </button>
                  ))}
              </div>
            </div>
          </div>

          <div className="row grid">
            {isLoadingProduct &&
              selectedOutlet &&
              deliveryType &&
              Array(8).fill(<MenuItemSkeleton />)}
            {isSuccessProducts &&
              currentProducts &&
              currentProducts.map((data, index) => (
                <MenuItemGrid item={data} key={`ssdd-${Math.floor(Math.random()*9999)}-${index}`} />
              ))}
         
          </div>
          <div className="fab-container">
          <button
            className="btn btn-success fab"
            onClick={
              ()=>{dispatch(toggleWebCart()); dispatch(toggleMobileCart())}
            }
          >
            <span className="cart-count">{useSelector((state) => state.order.cart.length)}</span>
            <i className="fas fa-shopping-cart"></i>
          </button>
          </div>

          <style jsx>{`
          .fab-container {
            position: fixed;
            bottom: 20px;
            right: 20px;
            z-index: 1000;
          }
          .fab {
            position: relative;
            width: 56px;
            height: 56px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
          }
          .cart-count {
            position: absolute;
            top: -10px;
            right: -10px;
            background: red;
            color: white;
            border-radius: 50%;
            padding: 5px 10px;
            font-size: 12px;
          }
          `}</style>
        </div>
      </section>
    </WebsiteLayout>
  );
};

export default Menu;
